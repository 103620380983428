<template>
  <div class="provider-filter" :class="{ 'provider-filter-collapse': isFilterOpen }">
    <div @click="toggleMenu" class="filter-title-wrap" :class="{ 'provider-filter-collapse': isFilterOpen }">
      <div class="filter-title" v-if="isFilterOpen === true"> {{ $i18n.t('messages.providerFilterTitle') }} </div>
      <div class="filter-arrow-wrap">
        <div class="filter-arrow" :class="{ 'provider-filter-collapse': isFilterOpen }"></div>
      </div>
    </div>
    <div class="filter-tab-wrap" :class="{ 'provider-filter-collapse': isFilterOpen }">
      <div class="filter-tab" :class="[{ 'provider-filter-collapse': isFilterOpen },{'scroll' : !isFilterOpen}]">
        <li v-for="(item, index) in categories" :key="index" class="filter-li"
            :class="[{ 'select':  $store.state.SelectedProviderCategory.id === item.id },{'um': item.isSuspended}, { 'selected': selectedItem === item }]"
            @click="handleItemClick(item)">
          
          <img class="filter-provider-logo" :src="item.iconPath">
          <span class="filter-provider-name">{{ item.name }}</span>
        </li>
      </div>
    </div>

  </div>
</template>

<script>
import GtmHelper from "@/components/utils/GtmHelper";
import i18n from "@/lang/i18n";
import axios from "axios";
import Axios from "axios";
import UrlHelper from "../libraries/UrlHelper";
import HeaderHelper from "../libraries/HeaderHelper";

export default {
  name: "ProviderFilter",
  mixins: [GtmHelper],
  data() {
    return {
      isFilterOpen: false,
      categories: [],
      providerFilterTitle: "",
      selectedItem: null
    };
  },
  computed: {
   
  },
  methods: {
    desktopLazyLoad(lazyImages){
      var options = {
        rootMargin: '300px',
        threshold: 0.5
      }

      var watcher = new IntersectionObserver(onEnterView, options);
      lazyImages.forEach(image=> watcher.observe(image));
      function onEnterView(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            var img = entry.target;
            img.setAttribute('src', img.dataset.src);
            img.removeAttribute('data-src');
            observer.unobserve(img);
          }
        });
      }
      clearInterval(this.isDesktopChangeIcon);
    },
    mobileLazyLoad(lazyImages){
      var options = {
        rootMargin: '300px',
        threshold: 0.5
      }

      var watcher = new IntersectionObserver(onEnterView, options);
      lazyImages.forEach(image=> watcher.observe(image));
      function onEnterView(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            var img = entry.target;
            img.setAttribute('src', img.dataset.src);
            img.removeAttribute('data-src');
            observer.unobserve(img);
          }
        });
      }
      clearInterval(this.isMobileChangeIcon);
    },
    changeGameIcon(){
      let vm = this;
      this.isDesktopChangeIcon = setInterval(function(){
        var lazyImages = document.querySelectorAll('img.desktopPromoLazy');
        if (lazyImages.length > 0){
          vm.desktopLazyLoad(lazyImages);
        }
      }, 500);
      this.isMobileChangeIcon = setInterval(function(){
        var lazyImages = document.querySelectorAll('img.mobilePromoLazy');
        if (lazyImages.length > 0){
          vm.mobileLazyLoad(lazyImages);
        }
      }, 500);
    },
    toggleMenu() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    handleItemClick(item) {
      if (item.isSuspended) {
        return;
      }
      this.selectedItem = item;
      setTimeout(() => {
        this.isFilterOpen = false;
        this.$nextTick(() => {
        this.scrollToSelectedItem();
      });
      }, 1000);
      this.$store.commit('SET_SelectedProviderCategory',item);
      this.changeGameIcon();
    },
    initSelectedItemIndex(respData) {
      let selectedItemIndex = 0;
      const firstNotSuspendedProvider = respData.findIndex(x => x.isSuspended === false);
      if (firstNotSuspendedProvider !== -1) {
        selectedItemIndex = firstNotSuspendedProvider;
      }
      this.$store.commit('SET_SelectedProviderCategory',respData[selectedItemIndex]);
      this.changeGameIcon();
    },
    async getProviderCategories() {
      const chaliuApiUrl = UrlHelper.getChaliuApiUrl();

      const apiUrl = new URL(`${chaliuApiUrl}/event/EventCategories/${this.$store.state.SelectedEventType}/${this.$store.state.SelectedEventId}?`);

      apiUrl.searchParams.set('lang', this.$store.state.UserInfo.lang);
      apiUrl.searchParams.set('device', this.$store.getters.Get_Device);
      if (!this.$store.getters.Is_Asi) {
        apiUrl.searchParams.set('brand', this.$store.state.Brand);
        apiUrl.searchParams.set('sboCurrency', this.$store.state.UserInfo.sboCurrency);
      }

      await Axios.get(apiUrl.href, {
        headers: HeaderHelper.getMultiPromotionHeaderOptions(this.$store.state.UserInfo.encryptedCustId)
      }).then((resp) => {
        if (resp.data == null) {
          return;
        }
        this.categories = resp.data;
      })
    },
    scrollToSelectedItem() {
      const selectedItem = document.querySelector('.filter-li.select');
      if (selectedItem) {
        const scrollContainer = document.querySelector('.filter-tab');
        const scrollTabWidth = document.querySelector('.filter-tab-wrap').offsetWidth ;

        if (scrollContainer.scrollLeft + scrollTabWidth - selectedItem.offsetLeft - 230 > 0 && scrollContainer.scrollLeft < selectedItem.offsetLeft) {
          return;
        }
        
        if (selectedItem.offsetLeft > scrollContainer.scrollLeft + scrollTabWidth / 2) {       
          const distanceToScroll = selectedItem.offsetLeft-scrollContainer.scrollLeft - scrollTabWidth + 206;
          scrollContainer.scrollLeft += distanceToScroll;
        } else { 
          const distanceToScroll = scrollContainer.scrollLeft -selectedItem.offsetLeft + 40          
          scrollContainer.scrollLeft -= distanceToScroll;
        }
      }
    },
  },
  async created() {
    console.log('created')
    await this.getProviderCategories();
    this.initSelectedItemIndex(this.categories);
  },
  
};
</script>

<style lang="scss" scoped>
@import "../scss/base/colors.scss";

@media (min-device-width: 2560px) {
  .provider-filter {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    
    &.provider-filter-collapse{
      flex-direction: column;
      padding: 10px 20px 10px 10px;

    }
    .filter-title-wrap {
      width: 100%;
      height: 45px;
      padding: 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      z-index: 1;

      &.provider-filter-collapse {
        justify-content: space-between;
      }

      .filter-title {
        color: #fff;
        font-size: 22px;
        font-weight: bold;
        padding: 10px 10px 10px 0px;
      }

      .filter-arrow-wrap {
        .filter-arrow {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          transform: rotateZ(0deg) translateY(0);
          transition-duration: 0.1s;
          transform: rotateX(0deg);

          &.provider-filter-collapse {
            transform: rotateX(180deg);
          }
        }
      }
    }

    .filter-tab-wrap {
      width: 99%;
      &.provider-filter-collapse {
        width: 100%;
        position: initial;
        top: initial;
      }

      .filter-tab {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;

        &::after{
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none;
        }

        &::-webkit-scrollbar {
          background-color: $scrollBar;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $scrollThumb;
          border-radius: 50px;
        }

        &.scroll {
          position: relative !important;
          overflow-x: auto;
          overflow-y: hidden;
        }

        &.provider-filter-collapse {
          flex-wrap: wrap;
          justify-content: flex-start;

          .filter-li {
            margin: 0 2px 8px 0;           
          }
        }

        .filter-li {
          margin: 0 5px;
          height: 35px;
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 40px;
          font-size: 16px;
          background-color: #03235A;
          border: 2.8px #1CA75E solid;
          color: #fff;
          cursor: pointer;

          &.select {
            background-color: #143CDB;
            border: 2px #143CDB solid;
          }

          &.um {
            opacity: 0.5;
            cursor: auto;
          }

          .filter-provider-logo {
            width: 30px;
            padding: 5px;
          }

          .filter-provider-name {
            min-width: 110px;
            width: fit-content;
            margin-right: 10px;
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-device-width: 2559px) {
  .provider-filter {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    
    &.provider-filter-collapse{
      flex-direction: column;
      padding: 10px 20px 10px 10px;

    }
    .filter-title-wrap {
      width: 100%;
      height: 45px;
      padding: 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      z-index: 1;

      &.provider-filter-collapse {
        justify-content: space-between;
      }

      .filter-title {
        color: #fff;
        font-size: 22px;
        font-weight: bold;
        padding: 10px 10px 10px 0px;
      }

      .filter-arrow-wrap {
        .filter-arrow {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          transform: rotateZ(0deg) translateY(0);
          transition-duration: 0.1s;
          transform: rotateX(0deg);

          &.provider-filter-collapse {
            transform: rotateX(180deg);
          }
        }
      }
    }

    .filter-tab-wrap {
      width: 99%;
      &.provider-filter-collapse {
        width: 100%;
        position: initial;
        top: initial;
      }

      .filter-tab {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;

        &::after{
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none;
        }

        &::-webkit-scrollbar {
          background-color: $scrollBar;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $scrollThumb;
          border-radius: 50px;
        }

        &.scroll {
          position: relative !important;
          overflow-x: auto;
          overflow-y: hidden;
        }

        &.provider-filter-collapse {
          flex-wrap: wrap;
          justify-content: flex-start;

          .filter-li {
            margin: 0 2px 8px 0;           
          }
        }

        .filter-li {
          margin: 0 5px;
          height: 35px;
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 40px;
          font-size: 16px;
          background-color: #141144;
          border: 2.8px #1CA75E solid;
          color: #fff;
          cursor: pointer;

          &.select {
            background-color: #123AC7;
            border: 2px #123AC7 solid;
          }

          &.um {
            opacity: 0.5;
            cursor: auto;
          }

          .filter-provider-logo {
            width: 30px;
            padding: 5px;
          }

          .filter-provider-name {
            min-width: 110px;
            width: fit-content;
            margin-right: 10px;
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-device-width: 768px) {
  .provider-filter {
    padding: 5px;
    padding: 0;

    &.provider-filter-collapse {
      background-image: linear-gradient(to bottom, rgba(0, 2, 38, 1) 5%, rgba(0, 2, 38, 0.1) 50%, rgba(0, 2, 38, 0.1) 100%);
      padding: 10px;
    }

    .filter-title-wrap {
      .filter-title {
        font-size: 16px;
      }

      .filter-arrow-wrap{
        padding: 0 5px;
      }
    }

    .filter-tab-wrap {
      .filter-tab {
        padding-bottom: 0px;
        &.scroll{
          margin: 8px 0 2px 40px;
        }
        &::-webkit-scrollbar {
          background-color: $scrollBar;
          height: 3px;
        }
        &.provider-filter-collapse {
          justify-content: center;
        }
        .filter-li {
          width: fit-content;
          height: 30px;
          margin: 0 2px 6px 0;

          .filter-provider-logo {
            width: 38px;
            padding: 3px;
          }
          .filter-provider-name {
            min-width: 85px;
            width: fit-content;
            margin-right: 10px;
            font-size: 12px;
          }
        }
      }
    }

  }
}
</style>
  