export default {
    ko_kr: {
        messages: { 
            providerFilterTitle:"제공사별 보기",
            step1: "<span style='color: yellow;'>아무 슬롯 게임</span>에서 스핀을 하고 상태바를 채우세요!",
            step2: "캐시 박스에서 <span style='color: yellow;'>3개의 잼</span>을 모으고 <span style='color: yellow;'>캐시 퀘스트</span>를 잠금 해제하세요!",
            step3: "모든 잼을 모으면 <span style='color: yellow;'>캐시 퀘스트</span>가 활성화됩니다.",
            tutorialContent: " <span style='color: yellow;text-decoration: underline;'>보상</span> 페이지로 이동하여 일일 캐시 박스 보상을 확인 하세요.",
            gamesTitle: "자격 요건 게임들",
            gamesSubTitle: "아무 슬롯 게임에서 스핀을 하고 일일 캐시 보상을 더욱 늘려보세요!",
            howToPlayTitle: "캐시박스 획득 방법?",
            rebateHowToPlayTitle: "획득 방법 캐시 퀘스트",
            rebateHowToPlaySubTitle: "매 스핀마다 늘어나는 보상!",
            rebateHowToPlayContent: "매 스핀마다 <span style='color: yellow;'>캐시 퀘스트</span>의 보상이 늘어납니다. 계속 스핀하세요!",
            rebateTutorialContent: "시간제한이 만료되면, 자신의 계정에 캐시 보상이 지급됩니다.  <span style='color: yellow;'>보상</span> 페이지에서 일일 보상 기록을 확인하세요.",
            tnc: "이용약관 적용",
            rotateText: "화면을 돌려서 세로 모드로 더 자세한 정보를 확인할 수 있습니다.",
        }
    },
};
