export default {
    th_th: {
        messages: { 
            providerFilterTitle:"เลือกผู้ให้บริการ",
            step1: "สปินใน<span style='color: yellow;'>เกมสล็อตใด</span>ก็ได้เพื่อเติมแถบบาร์",
            step2: "รวบรวม<span style='color: yellow;'>อัญมณีให้ครบทั้ง 3</span> เม็ดจากกล่องเงินสด เพื่อปลดล็อค<span style='color: yellow;'>ภารกิจเงินสด</span>!",
            step3: "<span style='color: yellow;'>ภารกิจเงินสด</span>จะถูกเปิดเมื่อรวบรวมอัญมณีครบทั้งหมดแล้ว",
            tutorialContent: "ไปยัง  <span style='color: yellow;text-decoration: underline;'>รางวัล</span> เพื่อตรวจสอบกล่องเงินสดรายวันที่คุณได้รับ",
            gamesTitle: "เกมส์ที่ร่วมรายการ",
            gamesSubTitle: "สปินในเกมสล็อตเพื่อเพิ่มยอดรางวัลเงินสดรายวัน!",
            howToPlayTitle: "จะปลดล็อกกล่องเงินสด ได้อย่างไร?",
            rebateHowToPlayTitle: "วิธีการรับ ภารกิจเงินสด",
            rebateHowToPlaySubTitle: "รางวัลจะเพิ่มขึ้นทุกครั้งที่สปิน!",
            rebateHowToPlayContent: "<span style='color: yellow;'>ทุกการสปิน</span>จะเพิ่มรางวัลของภารกิจเงินสด สปินต่อไป!",
            rebateTutorialContent: "รางวัลเงินสดจะปรับเข้าบัญชีของคุณเมื่อหมดเวลา ตรวจสอบรางวัลรายวันที่คุณได้รับที่  <span style='color: yellow;'>รางวัล</span>",
            tnc:"เป็นไปตามข้อตกลงและเงื่อนไข",
            rotateText:"กรุณาหมุนอุปกรณ์ของคุณไปเป็นโหมดแนวตั้งเพื่อตรวจสอบเพิ่มเติม",
        }
    },
};
