export default {
    ja_jp: {
        messages: {
            providerFilterTitle:"プロバイダーでフィルターする",
            step1: "バーを満タンにするには、<span style='color: yellow;'>どれかのスロットゲーム</span>でスピンして下さい。",
            step2: "<span style='color: yellow;'>キャッシュクエスト</span>を解除するには、全ての<span style='color: yellow;'>3つの宝石</span>を集めてください。",
            step3: "<span style='color: yellow;'>キャッシュクエスト</span>は全ての宝石が集まれば有効になります。",
            tutorialContent: " <span style='color: yellow;text-decoration: underline;'>報酬</span>にて稼いだデイリーキャッシュボックスを確認しよう。",
            gamesTitle: "対象ゲーム",
            gamesSubTitle: "デイリーキャッシュの報酬を最大限もらえるようどれかのスロットゲームをスピンして下さい！",
            howToPlayTitle: "キャッシュボックス 解除方法",
            rebateHowToPlayTitle: "キャッシュクエストを稼ぐ方法",
            rebateHowToPlaySubTitle: "スピンごとに報酬が大きくなります！",
            rebateHowToPlayContent: "<span style='color: yellow;'>スピンごと</span>にキャッシュアウトクエストの報酬が貯まります。スピンし続けてください!",
            rebateTutorialContent: "時間切れとなったら、賞金がアカウントに反映されます。 <span style='color: yellow;'>報酬</span>で日々稼いだ額をご確認下さい。",
            tnc: "取引条件が適用されます",
            rotateText: "詳細を確認するには、デバイスをポートレートモードに回転させてください。",
        }
    },
};
