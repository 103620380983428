export default {
    my_mm: {
        messages: {
            providerFilterTitle:"ဝန်ဆောင်မှုပေးသူဖြင့် စစ်ထုတ်ပါ",
            step1: "ဘားတန်းကိုဖြည့်ရန် မည်သည့် <span style='color: yellow;'>စလော့ဂိမ်းကိုမဆို</span> လှည့်ပါ။",
            step2: "<span style='color: yellow;'>ငွေသားရှာပုံတော်</span> ဖွင့်ရန် ငွေသားသေတ္တာများမှ <span style='color: yellow;'>ကျောက်မျက်ရတနာ 3</span> လုံးကိုစုဆောင်းပါ!",
            step3: "ကျောက်မျက်များအားလုံးကို စုဆောင်းပြီးသည်နှင့် <span style='color: yellow;'>ငွေသားရှာပုံတော်ကို</span> စတင်နိုင်မည်ဖြစ်သည်။",
            tutorialContent: "သင်၏နေ့စဉ် ငွေသားသေတ္တာ၀င်ငွေများကို စစ်ဆေးရန်  <span style='color: yellow;text-decoration: underline;'>ဆုလာဘ်</span> သို့သွားပါ။",
            gamesTitle: "အကျုံးဝင်သော ဂိမ်းများ",
            gamesSubTitle: "သင်၏နေ့စဉ်ငွေသားဆုလာဘ်များ တိုးပွားလာစေရန် မည်သည့် စလော့ဂိမ်းကိုမဆို လှည့်ဆော့လိုက်ပါ!",
            howToPlayTitle: "ငွေသားသေတ္တာတွေကို ဘယ်လိုဖွင့်မလဲ",
            rebateHowToPlayTitle: "ငွေသားရှာပုံတော် ဘယ်လိုဖွင့်မလဲ",
            rebateHowToPlaySubTitle: "ဆုလာဘ်များသည် လှည့်ချက်တိုင်းနှင့်အတူ တိုးလာပါသည်!",
            rebateHowToPlayContent: "<span style='color: yellow;'>လှည့်ချက်တိုင်းသည်</span> ငွေသားရှာပုံတော်၏ဆုလာဘ်ကို တိုးစေသည်။ ဆက်လှည့်ပါ!",
            rebateTutorialContent: "အချိန်ပြည့်ပြီးသည်နှင့် ငွေသားဆုအား သင့်အကောင့်ထဲသို့ ထည့်ပေးပါမည်။ သင်၏နေ့စဉ်ဝင်ငွေများကို  <span style='color: yellow;'>ဆုလာဘ်</span> တွင် စစ်ဆေးပါ။",
            tnc: "စည်းမျဥ်းများနှင့် အခြေအနေများ အကျိုးသက်ရောက်မှုရှိသည်",
            rotateText: "ကျေးဇူးပြု၍ ပိုမိုကြည့်ရန် သင်၏စက်ကို ဒေါင်လိုက်ပုံစံဖြစ်အောင်လှည့်ပါ။",
        }
    },
};
