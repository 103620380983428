export default {
    pt_br: {
        messages: {
            quickViewItem1: "Classificação",
            quickViewItem2: "Bilhete",
            quickViewCheckMore: "Confira mais",
            quickViewCheckLess: "Verifique menos",
            currentTankingTitle: "Classificação atual",
            rankingNumber: "Não.",
            rankingUserId: "ID do usuário",
            betAmount: "Valor da aposta",
            spinNo: "Giros",
            winAmount: "Valor da vitória",
            rankingPrize: "Prêmio",
            turnoverTournamentText: "Torneio de Maior Volume de Negócios",
            betCountTournamentText: "Torneio de giros mais altos",
            winAmountTournamentText: "Highest Winning Amount Tournament",
            rankingTurnoverTitle: "Classificação de<br/>rotatividade mais alta",
            rankingSpinTitle: "Classificação de giros mais altos",
            rankingWinAmountTitle: "Classificação da maior<br/>quantia vencedora",
            winnersTitle: "Lista de vencedores",
            winnersTurnoverTitle: "Vencedores de maior<br/>volume de negócios",
            winnersSpinTitle: "Vencedores de giros mais altos",
            winnersWinAmountTitle: "Vencedores com maior<br/>valor de ganho",
            turnoverSubTitle: "Com base nos maiores valores de aposta",
            spinSubTitle: "Com base no número total de giros",
            winAmountSubTitle: "Com base no total não. do valor da vitória",
            unitSpin: "Giros",
            user: "Eu",
            unitProcess: "k",
            rankingWinner: "Vencedores do Concurso",
            luckydrawWinner: "Vencedores do Sorteio",
            countdownTitleStart: "O evento começa em",
            countdownTitleStartEnd: "O evento termina em",
            countdownTitleStartLast: "",
            countdownTitleStartEndLast: "",
            countdownTitleEnd: "Dias",
            countdownTitleFinish: "Evento Finalizado! Os vencedores serão anunciados em breve!",
            tnc: "Termos e Condições",
            rotateText: "Gire seu dispositivo para o modo retrato para verificar mais.",
            dailyMissionTitle: "Missão Diária",
            step1: "Gire <span style='color: yellow;'>qualquer jogo slot</span> para preencher a barra.",
            step2: "Colete as <span style='color: yellow;'>3 Joias</span> das Caixas de Dinheiro para desbloquear o <span style='color: yellow;'>Procurar Dinheiro</span>!",
            step3: "<span style='color: yellow;'>Procurar Dinheiro</span> será ativado assim que todas as joias forem coletadas.",
            tutorialContent: "Acesse em  <span style='color: yellow;text-decoration: underline;'>Recompensa</span> para verificar seus ganhos diários da Caixa de Dinheiro.",
            gamesTitle: "Jogos qualificados",
            gamesSubTitle: "Gire em qualquer jogo slot para maximizar suas recompensas diárias em dinheiro!",
            howToPlayTitle: "Como desbloquear caixas de dinheiro?",
            rebateHowToPlayTitle: "Como Ganhar Procurar Dinheiro",
            rebateHowToPlaySubTitle: "As recompensas aumentam a cada giro!",
            rebateHowToPlayContent: "<span style='color: yellow;'>Cada rodada</span> alimenta a recompensa do Procurar Dineiro. Continue Girando!",
            rebateTutorialContent: "O prêmio em dinheiro será creditado em sua conta assim que o tempo acabar. Verifique seus ganhos diários na  <span style='color: yellow;'>Recompensa</span>."
        }
    },
};
