export default {
    en: {
        messages: { 
            providerFilterTitle:"Filter by Provider",
            step1: "Spin on <span style='color: yellow;'>any slot games</span> to fill up the bar.",
            step2: "Collect all <span style='color: yellow;'>3 Gems</span> from the Cash Boxes to unlock <span style='color: yellow;'>Cash Quest</span>!",
            step3: "<span style='color: yellow;'>Cash Quest</span> will be activated once all the gems are collected.",
            tutorialContent: "Head to <span style='color: yellow;text-decoration: underline;'>Rewards</span> to check your daily Cash Box earnings.",
            gamesTitle: "Qualifying Games",
            gamesSubTitle: "Spin on any slot games to maximise your daily cash rewards!",
            howToPlayTitle: "How to Unlock Cash Boxes?",
            rebateHowToPlayTitle: "How to Earn Cash Quest",
            rebateHowToPlaySubTitle: "Rewards grow with every spin! ",
            rebateHowToPlayContent: "<span style='color: yellow;'>Every spin</span> fuels the Cash Quest's reward. Keep spinning!",
            rebateTutorialContent: "Cash prize will be credited to your account once the time is up. Check your daily earnings at <span style='color: yellow;'>Rewards.</span>",
            rankingWinner: "Contest Winners",
            luckydrawWinner: "Lucky Draw Winners",
            tnc: "Terms & Conditions Apply",
            rotateText: "Please rotate your device to portrait mode to check more.",
        }
    },
};
