<template>
  <div id="app" :class="'app_'+view">
    <div class="eventTemplate" :class="[ { 'id-id': (lang =='id-id' || lang =='id_id') }, view]">
      <MobileLandscape v-if="view=='mobileLandscape'" :stage="stage" :view="view" :lang="lang"
                       :eventGameList="eventGameList"/>
      <MobilePortrait v-if="view=='mobilePortrait'" :eventName="eventName" :stage="stage" :view="view" :lang="lang"
                      :promotionData="promotionData" :eventGameList="eventGameList" :eventImages="eventImages"/>
      <Desktop v-if="view=='desktop'" :eventName="eventName" :stage="stage" :view="view" :lang="lang"
               :promotionData="promotionData"
               :eventGameList="eventGameList" :eventImages="eventImages"/>
      <div v-show="showMore" class="bottomMore">
        <SvgIcon iconName="popupIcon_chevron_right"/>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import MobilePortrait from './views/MobilePortrait.vue';
import MobileLandscape from './views/MobileLandscape.vue';
import Desktop from './views/Desktop.vue';
import SvgIcon from "@/components/SvgIcon.vue";
import Axios from 'axios';
import EventEmitter from 'events'
import HeaderHelper from "@/libraries/HeaderHelper";
import UrlHelper from "@/libraries/UrlHelper";
import GtmHelper from "@/components/utils/GtmHelper";

class GamesEmitter extends EventEmitter {
}

export default {
  name: 'app',
  components: {
    MobilePortrait,
    MobileLandscape,
    Desktop,
    SvgIcon
  },
  mixins: [GtmHelper],
  data() {
    return {
      eventName: '',
      stage: 'Promo', //PrePromo, Promo, Winner
      view: null, //mobilePortrait, desktop, mobileLandscape
      lang: 'en', //th_th, id_id, zh_cn, en
      showMore: false, //false true
      promotionData: {},
      eventGameList: [],
      showEventPage: false,
      resources: {},
      isMobileLayout: false,
      userInfo: null,
      eventId: {
        type: Number,
      },
      eventImages: {},
    }
  },
  async created() {
    await this.loadFromTalosCookie();
    this.$store.commit('SET_SelectedEventType', "ProgressBar");
    this.$store.commit('SET_IsMobileDevice');

    const localStorageComeFrom = localStorage.getItem('ComeFrom');
    const comeFromQuery = new URLSearchParams(window.location.search).get('ComeFrom');
    // const comeFromQuery = (new URLSearchParams(window.location.href)).get('ComeFrom');

    this.$store.commit('SET_ComeFrom', comeFromQuery === null ? localStorageComeFrom : comeFromQuery);
    this.$store.commit('SET_GameFrom');
    this.$store.commit('SET_IsEmbedded');
    this.$store.commit('SET_Layout');

    await this.viewLobbyGtm();

    //maybe cloud be removed
    this.getLuckyDrawResources();


    window.addEventListener('resize', this.handleRotate);
    this.handleRotate();

    window.GamesEmitter = new GamesEmitter();
    window.GamesEmitter.on('openGame', (game) => {
      if (!this.$store.state.IsOnApp && this.isPlayerReachMaxWinlostLimit()) {
        this.showErrorAlert();
      } else {
        const gameFrom = this.$store.state.GameFrom;
        if (gameFrom !== undefined  && gameFrom !== '' && gameFrom !== null) {
          this.openGame(game, gameFrom);
        } else if (this.view === 'desktop') {
          this.openGame(game, "10914");
        } else {
          this.openGame(game, "20914");
        }
      }
    });
    window.GamesEmitter.on('banneropenGame', (game) => {
      if (!this.$store.state.IsOnApp && this.isPlayerReachMaxWinlostLimit()) {
        this.showErrorAlert();
      } else {
        if (this.view == 'desktop') {
          this.openGame(game, "10918");
        } else {
          this.openGame(game, "20918");
        }
      }
    });
  },
  mounted() {
    document.getElementById('app').addEventListener('scroll', this.handleScroll);

    this.getLuckyDrawResources();
  },
  beforeDestroy() {
    this.unloadThemePack();
  },
  destroyed() {
    this.unloadThemePack();
    document.getElementById('app').removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleRotate);
  },
  watch: {
    SelectedProviderCategory: async function (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.eventGameList = []
      this.eventGameList = await this.getEventCategoryGamesByCategory(newVal.id);
    }
  },
  methods: {
    unloadThemePack() {
      this.removeDom('cash-box-event-script');
      if (process.env.VUE_APP_ENV !== 'staging') {
        this.removeDom('cash-box-event-css');
      }
    },
    removeDom(domId) {
      const target = document.getElementById(domId);
      target.remove();
    },
    async loadFromTalosCookie() {
      let userInfo = JSON.parse(decodeURIComponent(Cookies.get('IJ_promotion_userInfo')));
      const isApp = Cookies.get('IJ_promotion_isOnApp');
      if (isApp && isApp.toLowerCase() == 'true') {
        this.$store.commit('SET_IsOnApp', true);
      } else {
        this.$store.commit('SET_IsOnApp', false);
      }

      const localStorageSelectedEventId = localStorage.getItem('SelectedEventId');
      if (localStorage.getItem('SelectedEventId') !== null) {
        localStorage.removeItem('SelectedEventId');
      }
      //only app will have event id in query
      const eventIdFromQuery = window.location.pathname.split('/')[3];

      this.eventId = localStorageSelectedEventId === null ? eventIdFromQuery : localStorageSelectedEventId;

      this.$store.commit('SET_SelectedEventId', this.eventId);
      this.$store.commit('SET_IsMobileLayout', Cookies.get('isMobileLayout'));
      this.$store.commit('SET_Brand', Cookies.get('IJ_promotion_brand'));
      this.$store.commit('SET_UserInfo', userInfo);
    },
    handleScroll() {
      var page = document.getElementById('app');
      var scrollTop = page.scrollTop;
      var maxScroll = document.getElementsByClassName("eventTemplate")[0].clientHeight - page.clientHeight;
      if (scrollTop >= maxScroll - 5) {
        this.showMore = false;
      } else {
        this.showMore = true;
      }
    },
    async getEventCategoryGamesByCategory(category) {
      const chaliuApiUrl = UrlHelper.getChaliuApiUrl();
      const apiUrl = new URL(`${chaliuApiUrl}/Event/EventCategoryGameList/${this.$store.state.SelectedEventType}/${this.eventId}`);
      if (!this.$store.getters.Is_Asi) {
        apiUrl.searchParams.set('brand', this.$store.state.Brand);
        apiUrl.searchParams.set('sboCurrency', this.$store.state.UserInfo.sboCurrency);
      }
      apiUrl.searchParams.set('device', this.$store.getters.Get_Device);
      apiUrl.searchParams.set('lang', this.$store.state.UserInfo.lang);
      apiUrl.searchParams.set('category', category);

      var response = await Axios.get(apiUrl.href, {
        headers: HeaderHelper.getMultiPromotionHeaderOptions(this.$store.state.UserInfo.encryptedCustId)
      });
      return response.data;
    },
    getLuckyDrawResources() {

      var nResource = {
        step1: this.i18nConfig.t('messages.step1'),
        step2: this.i18nConfig.t('messages.step2'),
        step3: this.i18nConfig.t('messages.step3'),
        gamesTitle: this.i18nConfig.t('messages.gamesTitle'),
        gamesSubTitle: this.i18nConfig.t('messages.gamesSubTitle'),
        quickViewItem1: this.i18nConfig.t('messages.quickViewItem1'),
        quickViewItem2: this.i18nConfig.t('messages.quickViewItem2'),
        quickViewCheckMore: this.i18nConfig.t('messages.quickViewCheckMore'),
        quickViewCheckLess: this.i18nConfig.t('messages.quickViewCheckLess'),
        currentTankingTitle: this.i18nConfig.t('messages.currentTankingTitle'),
        rankingNumber: this.i18nConfig.t('messages.rankingNumber'),
        rankingUserId: this.i18nConfig.t('messages.rankingUserId'),
        betAmount: this.i18nConfig.t('messages.betAmount'),
        spinNo: this.i18nConfig.t('messages.spinNo'),
        winAmount: this.i18nConfig.t('messages.winAmount'),
        rankingPrize: this.i18nConfig.t('messages.rankingPrize'),
        turnoverTournamentText: this.i18nConfig.t('messages.turnoverTournamentText'),
        betCountTournamentText: this.i18nConfig.t('messages.betCountTournamentText'),
        winAmountTournamentText: this.i18nConfig.t('messages.winAmountTournamentText'),
        rankingTurnoverTitle: this.i18nConfig.t('messages.rankingTurnoverTitle'),
        rankingSpinTitle: this.i18nConfig.t('messages.rankingSpinTitle'),
        winnersTitle: this.i18nConfig.t('messages.winnersTitle'),
        winnersTurnoverTitle: this.i18nConfig.t('messages.winnersTurnoverTitle'),
        winnersSpinTitle: this.i18nConfig.t('messages.winnersSpinTitle'),
        turnoverSubTitle: this.i18nConfig.t('messages.turnoverSubTitle'),
        spinSubTitle: this.i18nConfig.t('messages.spinSubTitle'),
        unitSpin: this.i18nConfig.t('messages.unitSpin'),
        user: this.i18nConfig.t('messages.user'),
        howToPlayTitle: this.i18nConfig.t('messages.howToPlayTitle'),
        rankingWinner: this.i18nConfig.t('messages.rankingWinner'),
        luckydrawWinner: this.i18nConfig.t('messages.luckydrawWinner'),
        countdownTitleStart: this.i18nConfig.t('messages.countdownTitleStart'),
        countdownTitleStartEnd: this.i18nConfig.t('messages.countdownTitleStartEnd'),
        countdownTitleStartLast: this.i18nConfig.t('messages.countdownTitleStartLast'),
        countdownTitleStartEndLast: this.i18nConfig.t('messages.countdownTitleStartEndLast'),
        tnc: this.i18nConfig.t('messages.tnc'),
        rotateText: this.i18nConfig.t('messages.rotateText'),
        dailyMissionTitle: this.i18nConfig.t('messages.dailyMissionTitle'),
        tutorialContent: this.i18nConfig.t('messages.tutorialContent'),
        rebateHowToPlayTitle: this.i18nConfig.t('messages.rebateHowToPlayTitle'),
        rebateHowToPlaySubTitle: this.i18nConfig.t('messages.rebateHowToPlaySubTitle'),
        rebateHowToPlayContent: this.i18nConfig.t('messages.rebateHowToPlayContent'),
        rebateTutorialContent: this.i18nConfig.t('messages.rebateTutorialContent')
      }

      this.resources = nResource;
    },
    openGame(game, gameFrom) {
      const isConfirmMobile = this.$store.state.IsMobileDevice && this.$store.state.IsMobileLayout;
      if (game.showIOMAlert && !this.$store.state.IsOnApp) {
        if (isConfirmMobile) {
          mobileNonIomPopupOpenGame(() => this._openGame(true, game, gameFrom));
        } else {
          desktopNonIomPopupOpenGame(() => this._openGame(false, game, gameFrom));
        }
      } else {
        this._openGame(isConfirmMobile, game, gameFrom);
      }
    },
    MM_openBrWindow(theURL, winName, features) {
      return this._MM_openBrWindow(theURL, winName, features);
    },

    handleRotate() { //mobilePortrait,mobileLandscape,desktop
      if (!(this.$store.state.IsMobileDevice && this.$store.state.IsMobileLayout)) {
        this.view = 'desktop';
      } else if (window.innerHeight > window.innerWidth) {
        this.view = 'mobilePortrait';
      } else {
        this.view = 'mobileLandscape';
      }
    },
    isPlayerReachMaxWinlostLimit() {
      return this.$store.state.UserInfo.gameReachLimit != 0;
    },
    showErrorAlert() {
      if (this.isPlayerReachMaxWinlostLimit()) {
        if (this.$store.state.UserInfo.gameReachLimit == 10021) {
          alert('You have exceeded the maximum win limit. To continue, please contact your upper line (Agent).');
        }

        if (this.$store.state.UserInfo.gameReachLimit == 10022) {
          alert('You have exceeded the maximum loss limit. To continue, please contact your upper line (Agent).');
        }
      }
    },
    getImagePath() {
      const lang = this.$store.state.UserInfo.lang;
      if (this.view == 'desktop') {
        return this.images.desktopEventImagePaths[lang];
      } else if (this.view == 'mobilePortrait' || this.view == 'mobileLandscape') {
        return this.images.mobileEventImagePaths[lang];
      }
    },
    _MM_openBrWindow(theURL, winName, features) {
      const wHandle = window.open(theURL, winName, features);
      wHandle.focus();
      return wHandle;
    },
    _openGame(isConfirmMobile, game, gameFrom) {
      if (this.$store.state.IsOnApp && this.$store.getters.isOuterLobby) {
        window.parent.postMessage(JSON.stringify({
          topic: 'wc_app_open_game',
          data: {
            gameCode: game.gameCode,
          },
        }), '*');
      } else {
        let openGameLang = this.$store.state.UserInfo.lang.toLowerCase().replace('_', '-');
        if (isConfirmMobile) {
          window.top.location.href = `/Home/RedirectToGame?GameCode=${game.gameCode}&GameFrom=${gameFrom}&Lang=${openGameLang}`;
        } else
          this._MM_openBrWindow(`/Home/RedirectToGame?GameCode=${game.gameCode}&GameFrom=${gameFrom}&Lang=${openGameLang}`, 'play', 'scrollbars=no,resizable=yes,width=1200,height=770');
      }
    },
    async viewLobbyGtm() {
      if (this.$store.getters.Is_Asi && this.$store.getters.Get_Device !== 'App' && this.$store.getters.isOuterLobby) {
        const userInfo = await this.getUserInfo();
        this.gtmDataLayer().pushToDataLayer('view', {
          event: 'common',
          identify: userInfo.isB2C2CMember ? 'B2C2C' : userInfo.isB2CMember ? 'B2C' : 'B2B',
          type: userInfo.isIom ? 'IOM' : 'MNL',
          currency: userInfo.currency,
          brand: userInfo.brand === 0 ? 'SBOBET' : 'SBOTOP',
          projectName: 'TL',
          product: 'GamesWorldCupPromotion',
          uuid: userInfo.trackingId,
        });
      }
    },
    async getUserInfo() {
      const resp = await Axios.get(`${process.env.VUE_APP_API_URL}/asi/UserInfo`);
      if (resp.status === 200) {
        this.isReady = true;
        return resp.data;
      }

      console.error('GetUserInfo failed');
      return null;
    },
  },
  computed: {
    i18nConfig() {
      return this.$i18n;
    },
    SelectedProviderCategory() {
      return this.$store.state.SelectedProviderCategory;
    }
  }
}

</script>

<style lang="scss">
@import "./scss/eventTemplate";
</style>
