<template>
  <div class="games" >
    <AreaTitle :title="title" :subTitle="subTitle"/>
    <div class="games-list">
<!--       skeleton for game list is empty-->
      <template v-if="games.length === 0" v-for="index in 40" >
        <li class="list-item">
          <!--    if isMobile == ture then class should be 'mobile-game-item' else should be 'game-item'    -->


          <div :class="[isMobile ? 'mobile-game-item': 'game-item']" >
            <div class="games-icon desktopPromoLazy skeleton noGame"  />
          </div>
        </li>
      </template>


      <li v-for="(item,index) in games"  :key="item.gameCode" :view="view" class="list-item"
          v-gtm:event="'games'"
          v-gtm:gtmAction="'Click'"
          v-gtm:gtmLabel="'Promoevent_Btn_clickgameicon'"
          v-gtm:gtmCategory="'PromoLobby'"
          v-gtm:source = "getGtmDataSource()"
          v-gtm:promoeventname ="eventName"
          v-gtm:value = "item.gameName"
          v-gtm:@click
          @click="openGame(item)">
        <template v-if="isMobile">
          <div class="mobile-game-item">
            <img v-if="item.isHotGame" class="mobile-game-item-hot-tag skeleton" :src="`${cdnUrl}/images/mobile/hot_tag_${lang}.png`" loading="lazy">
            <img ref="mobileIcon" @error="onMobileImageLoadFailure($event,item.gameCode)" @load="debounceResize" class="games-icon mobilePromoLazy skeleton"
              :class="[index === 16 ? 'multipromo-section' : '']"
              :src="generateThumbIconUrl(item.gameCode)"
              :data-src="generateIconUrl(item.gameCode)" loading="lazy">
          </div>
        </template>
        <template v-else>
          <div class="game-item">
            <div class="game-item-mask"></div>
            <div v-if="item.isHotGame" class="game-item-hot-icon" :style="`background: url(${cdnUrl}/images/base/hot_tag_${lang}.png);`" ></div>
            <img ref="desktopIcon" @error="onImageLoadFailure($event,item.gameCode)" @load="debounceResize" class="games-icon desktopPromoLazy skeleton"
              :class="[index === 16 ? 'multipromo-section' : '']"
              :data-src="generateIconUrl(item.gameCode)"
              :src="`${cdnUrl}/images/base/transparent.png`" loading="lazy">
            <a class="game-enter"><span>{{ enterGameTitle }}</span></a>
          </div>
          <span class="game-name">{{item.gameName}}</span>
        </template>
      </li>
    </div>
  </div>
</template>

<script>
import AreaTitle from "@/components/AreaTitle.vue";
import Axios from 'axios';
import GtmHelper from "@/components/utils/GtmHelper";
import lodash from 'lodash';
export default {
  name: "Games",
  components: {
    AreaTitle
  },
  mixins: [GtmHelper],
  props: {
    promotionType: {
      type: String,
      default: function () {
        return "";
      }
    },
     cdnUrl: {
      type: String,
      default: function () {
        return "";
      }
    },
    stage: {
      type: String,
      default: function() {
        return "PrePromo";
      }
    },
    view: {
      type: String,
      default: function() {
        return "mobilePortrait";
      }
    },
    games: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isMobile:{
      type: Boolean,
      default: function(){
        return false;
      }
    },
    resources: {
      type: Object,
      default: function() {
        return {};
      }
    },
    eventName:{
      type: String,
    },
  },
  data: function() {
    return {
      title: "",
      subTitle: "",
      isDesktopChangeIcon: false,
      isMobileChangeIcon:false,
      ImgVersion:'',
      my:this,
    }
  },
  watch: {
    resources: function() {
      this.title = this.resources.gamesTitle;
      this.subTitle = this.resources.gamesSubTitle
    }
  },
  mounted() {
    this.getImgVersion();
  },
  methods: {
    debounceFun : lodash.debounce((fun) => {
      fun();
    }, 500),
    debounceResize(){
      this.debounceFun(this.postResizeMessage);
    },
    postResizeMessage(){
      const iframeHeight = document.activeElement.scrollHeight;
      window.parent.postMessage(JSON.stringify({
        topic: 'wc_leaderboard_resize_iframe',
        data: {
          iframeHeight: iframeHeight + 5,
        },
      }), '*');
    },
    getImgVersion(){
      Axios.get(`${process.env.VUE_APP_API_URL}/asi/getCdnInfo`).then((resp)=>{
         this.ImgVersion = resp.data.ImgVersion;
      })
    },
    onImageLoadFailure(event,gameCode)
    {
      var cdnImageFolder = null;
      if (this.isMobileDevice) {
        cdnImageFolder = `${this.cdnUrl}/images/games/mobileDesktop`;
      }
      else {
        cdnImageFolder = `${this.cdnUrl}/images/games`;
      }
      // handle if default image not found, should not show game
      if (event.target.src.includes("-en")) {
        event.target.parentElement.parentElement.style.display = "none";
      }else{
        event.target.src = `${cdnImageFolder}/GameImg${gameCode}-en.png?${this.promotionType}ab`;
      }
    },
    onMobileImageLoadFailure(event,gameCode)
    {
      event.target.src = `${this.cdnUrl}/images/icons/mobile/GameImg${gameCode}-en.png?${this.promotionType}ab`;
    },
    generateThumbIconUrl(gameCode){
        var thumbType = 2;
        var queryPath = new URLSearchParams(`id=${gameCode}&ratio=${window.devicePixelRatio}&lang=${this.lang}&type=${thumbType}&version=${this.ImgVersion}`);
        queryPath.sort();

        return `${this.cdnUrl}/resource/image?${queryPath}`;
    },
    generateIconUrl(gameCode){

      if (this.isMobile){
        //mobile icon
        var queryPath = new URLSearchParams(`id=${gameCode}&ratio=${window.devicePixelRatio}&lang=${this.lang}&version=${this.ImgVersion}`);
        queryPath.sort();

        return `${this.cdnUrl}/resource/image?${queryPath}`;
      }

      var cdnImageFolder = null;
      if (this.isMobileDevice) {
        //mobile desktop
        cdnImageFolder = `${this.cdnUrl}/images/games/mobileDesktop`;
      }
      else {
        cdnImageFolder = `${this.cdnUrl}/images/games`;
      }
      switch (this.lang.toLowerCase()) {
          case 'zh_tw':
          case 'zh_cn':
              return `${cdnImageFolder}/GameImg${gameCode}-zh-cn.png?${this.promotionType}ab`;
          case 'id_id':
              return `${cdnImageFolder}/GameImg${gameCode}-id-id.png?${this.promotionType}ab`;
          case 'th_th':
              return `${cdnImageFolder}/GameImg${gameCode}-th-th.png?${this.promotionType}ab`;
          case 'ko_kr':
              return `${cdnImageFolder}/GameImg${gameCode}-ko-kr.png?${this.promotionType}ab`;
          case 'ja_jp':
              return `${cdnImageFolder}/GameImg${gameCode}-ja-jp.png?${this.promotionType}ab`;
          case 'vi_vn':
              return `${cdnImageFolder}/GameImg${gameCode}-vi-vn.png?${this.promotionType}ab`;
          case 'my_mm':
              return `${cdnImageFolder}/GameImg${gameCode}-my-mm.png?${this.promotionType}ab`;
          default:
              return `${cdnImageFolder}/GameImg${gameCode}-en.png?${this.promotionType}ab`;
      }
    },
      openGame(game) {
          window.GamesEmitter.emit('openGame', game);
      },
      openImage(imgUrl,lang){
        var imageExists = require('image-exists');
        var imageUrl = imgUrl + "-" + lang.replace("_","-") + ".png";
        imageExists(imageUrl, function(exists) {
          if (exists) {
            return imageUrl;
          }
          else {
            return imgUrl + "-en.png";
          }
        });
      },
  },
   computed: {
    lang() {
      return this.$store.state.UserInfo.lang;
    },
    isMobileDevice() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
    },
    enterGameTitle() {
      switch (this.lang.toLowerCase()) {
        case 'zh_tw':
        case 'zh_cn':
            return '立即投注';
        case 'id_id':
            return 'Main Sesungguhnya';
        case 'th_th':
            return 'เล่นจริง';
        case 'ko_kr':
            return '리얼머니 게임';
        case 'ja_jp':
            return '実際にプレー';
        case 'vi_vn':
            return 'Chơi Thiệt';
        case 'my_mm':
            return 'တကယ်ကစားမယ်';
        case 'pt_br':
            return 'Entrar no jogo';
        default:
            return 'Enter Game';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/colors.scss";

@keyframes skeleton {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 280px;
  }
}
.noGame{
  width: 100%;
  padding-bottom: 50%;
}
.skeleton {
  background-image: linear-gradient(98deg, #20224A 0px, #222552 40px, #20224A 80px);
  background-size: 600px;
  animation: skeleton 1.5s infinite linear;
}


.games {
  padding: 0 1.2rem 1.2rem 1.2rem;
  text-align: center;
  position: relative;
  z-index: 2;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 30rem;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-image: var(--gameBgTopMobile);
    background-size: cover;
    pointer-events: none;
  }

  .areaTitle{
    margin-bottom: 0.2rem;
  }

  .games-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: auto;

    .list-item {
      width: 80px;
      max-width: 20%;
      margin: 0.6rem;
      list-style: none;
      cursor: pointer;

      &:hover {
        .game-item-mask {
          opacity: 0.75;
        }

        .game-enter span {
          opacity: 1;
        }
      }

      .games-icon {
        width: 100%;
        border-radius: 5px;
        display: block;
        //height: 100%;
        background-image: linear-gradient(90deg, rgb(255,255,255,0.15) 0px,rgb(255,255,255,0.20) 40px, rgb(255,255,255,0.15) 80px);
        background-size: 600px;
        animation: skeleton 1.5s infinite linear;
      }

      .game-name {
        color: $game-name;
        font-size: 14px;
        margin-top: 4px;
        display: block;
      }
    }
  }
}

.game-item {
  position: relative;

  .game-item-hot-icon {
      position: absolute;
      left: 0px;
      top: -10px;
      width: 45px;
      height: 45px;
      background: no-repeat;
      background-size: cover !important;
      z-index: 99;
  }

  &-mask {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .75);
    z-index: 1;
    opacity: 0;
    transition: all .2s linear;
    border-radius: 5px;
    cursor: pointer;
  }

  .game-enter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    font-size: 14px;
    z-index: 3;

    span {
      position: absolute;
      width: 140px;
      height: 40px;
      border-radius: 100px;
      border: 2px solid #5df0f4;
      left: 50%;
      top: 50%;
      margin-left: -70px;
      margin-top: -20px;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      opacity: 0;
    }
  }
}

.mobile-game-item {
  position: relative;

  &-hot-tag {
    width: 100%;
    position: absolute;
    top: -10px;
    left: -10px;
  }
}

@keyframes skeleton {
    0% {
        background-position: -32px;
    }
    40%, 100% {
        background-position: 208px;
    }
}

@media (max-device-width: 768px) {
  .noGame{
    width: 100%;
    padding-bottom: 100%;
  }
}

</style>
