<template>
  <img class="eventKV" :src = "getEventImageUrlInProject()"/>
  <!-- <img
    :src="getEventImageUrl()"
    v-gtm:event="'games'"
    v-gtm:gtmAction="'Click'"
    v-gtm:gtmLabel="'Promoevent_Banner_click'"
    v-gtm:gtmCategory="'PromoLobby'"
    v-gtm:source="getGtmDataSource()"
    v-gtm:promoeventname="eventName"
    v-gtm:@click
  > -->
</template>

<script>
import GtmHelper from "@/components/utils/GtmHelper";
import LanguageHelper from "@/libraries/LanguageHelper";
export default {
  name: "EventKV",
    props: {
        eventName: {
            type: String,
            default: function () {
                return "";
            }
        },
        view: {
            type: String,
            default: function () {
                return "mobilePortrait";
            }
        },
        eventImages: {
          type: Object,
          default: function () {
              return {};
          }
        },
    },
    mixins: [GtmHelper],
    computed:{
      bannerUrl() {
        if (this.view === 'desktop'){
          return this.$store.state.DesktopPromotionBannerSrcUrl;
        } else {
          return this.$store.state.MobilePromotionBannerSrcUrl;
        }
      },
    },
    methods: {
      GetImgFromCDN(imgUrl){
        return `${process.env.VUE_APP_CDN_URL}${imgUrl}?ver=${this.promotionType}`;
      },
      getEventImageUrl(){
        if (this.view === 'desktop'){
          return this.eventImages.desktopEventImagePath;
        } else {
          return this.eventImages.mobileEventImagePath;
        }
      },
      getEventImageUrlInProject() {
        let brand = (this.$store.getters.GetBrandFromUserInfo).toUpperCase();

        let sboCurrency = this.$store.state.UserInfo.sboCurrency.toUpperCase();
        let isIom = this.$store.state.UserInfo.isIom;

        const cashBoxCurrencyRestriction =  ["RM", "MMK", "KRW", "INR", "YEN", "RMB", "USDT", "BRL", "VND", "TB", "PP","USD","US$"];

        if (!cashBoxCurrencyRestriction.includes(sboCurrency)) {
          if (isIom) {
            sboCurrency = "EURO"
          }else{
            sboCurrency = "RM"
          }
        }

        if (this.view === 'desktop'){
          return require(`@/assets/images/kv/Desktop/${brand}/${sboCurrency}.jpg`);
        } else {
          return require(`@/assets/images/kv/Mobile/${brand}/${sboCurrency}.jpg`);
        }
      }
    },
};
</script>

<style lang="scss" scoped>
  .eventKV{
    position: relative;
    z-index: 1;
    width: 100%;
  }

</style>
