export default {
    vi_vn: {
        messages: { 
            providerFilterTitle:"Bộ lọc Nhà Cung Cấp",
            step1: "Chơi bất kỳ <span style='color: yellow;'>trò chơi slot quay hũ</span> để lấp đầy thanh tiến trình.",
            step2: "Thu thập tất cả <span style='color: yellow;'>3 viên Đá Quý</span> từ Hũ Tiền để mở khóa <span style='color: yellow;'>Nhiệm vụ Săn Kho Báu</span>!",
            step3: "<span style='color: yellow;'>Nhiệm vụ Săn Kho Báu</span> sẽ được kích hoạt sau khi tất cả đá quý được thu thập.",
            tutorialContent: "Đi tới  <span style='color: yellow;text-decoration: underline;'>Phần Thưởng</span> để kiểm tra tiền thưởng đã nhận từ Hũ Tiền của bạn.",
            gamesTitle: "Trò Chơi Hợp Lệ",
            gamesSubTitle: "Chơi bất kỳ trò slot quay hũ để tối đa phần thưởng tiền mặt hàng ngày của bạn!",
            howToPlayTitle: "Làm cách nào để Mở Khoá Hũ Tiền?",
            rebateHowToPlayTitle: "Làm Thế Nào Để Săn Kho Báu",
            rebateHowToPlaySubTitle: "Phần thưởng tăng lên sau mỗi lần quay!",
            rebateHowToPlayContent: "<span style='color: yellow;'>Quay càng nhiều</span>, thưởng càng lớn. Quay tiếp thôi!",
            rebateTutorialContent: "Giải thưởng tiền mặt sẽ được cộng vào tài khoản của bạn sau khi thời gian kết thúc. Kiểm tra tiền thưởng hàng ngày của bạn tại  <span style='color: yellow;'>Phần Thưởng</span>.",
            tnc: "Điều Khoản và Điều Kiện áp dụng",
            rotateText: "Vui lòng xoay thiết bị của bạn sang chế độ dọc để kiểm tra thêm.",
        }
    },
};