import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        MobilePromotionBannerSrcUrl: "",
        DesktopPromotionBannerSrcUrl: "",
        PromotionBannerName: "",
        SelectedEventId: 0,
        SelectedEventName: "",
        IsShowEventPopup: false,
        IsMobileDevice: false,
        IsOnApp: false,
        IsMobileLayout: false,
        Brand: null,
        UserInfo: null, //App and web using different UserInfo,
        Lang: "",
        SelectedEventType: "",
        ComeFrom: "",
        GameFrom: "",
        SelectedProviderCategory: null,
        IsEmbedded: false,
        Layout: "",
    },
    mutations: {
        SET_MobilePromotionBannerSrcUrl(state, data) {
            state.MobilePromotionBannerSrcUrl = data;
        },
        SET_DesktopPromotionBannerSrcUrl(state, data) {
            state.DesktopPromotionBannerSrcUrl = data;
        },
        SET_PromotionBannerName(state, data) {
            state.PromotionBannerName = data;
        },
        SET_SelectedEventId(state, data) {
            state.SelectedEventId = data;
        },
        SET_SelectedEventName(state, data) {
            state.SelectedEventName = data;
        },
        SET_IsShowEventPopup(state, data) {
            state.IsShowEventPopup = data;
        },
        SET_IsMobileDevice(state) {
            const flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );

            state.IsMobileDevice = flag != null && flag.length > 0;
        },
        SET_IsMobileLayout(state, data) {
            state.IsMobileLayout = data;
        },
        SET_UserInfo(state, data) {
            state.UserInfo = data;
        },
        SET_IsOnApp(state, data) {
            state.IsOnApp = data;
        },
        SET_Brand(state, data) {
            state.Brand = data;
        },
        SET_Lang(state, data) {
            state.Lang = data;
        },
        SET_SelectedEventType(state, data) {
            state.SelectedEventType = data;
        },
        SET_ComeFrom(state, data) {
            state.ComeFrom = data;
        },
        SET_GameFrom(state) {
            state.GameFrom = (new URL(document.location)).searchParams.get('GameFrom');
        },
        SET_IsEmbedded(state) {
            let isEmbedded = (new URL(document.location)).searchParams.get('IsEmbedded');
            if (isEmbedded != null) {
                state.IsEmbedded = isEmbedded.toLowerCase() === "true";
            }
        }, 
        SET_Layout(state) {
            let Layout = (new URL(document.location)).searchParams.get('Layout');
            if (Layout != null) {
                state.Layout = Layout.toLowerCase();
            }
        },
        SET_SelectedProviderCategory(state, data) {
            state.SelectedProviderCategory = data;
            //call actions changeGameIcon
        },
    },
    actions: {

    },
    getters: {
        Is_Asi: state => {
            return state.UserInfo != null && state.UserInfo.encryptedCustId !== '';
        },
        Get_Device: state => {
            return state.IsOnApp ? 'App' : (state.IsMobileDevice ? 'Mobile' : 'Desktop');
        },
        isOuterLobby: state => {
            return state.ComeFrom.toLowerCase() === 'outerlobby';
        },
        GetBrandFromUserInfo: state => {
            return state.UserInfo != null ? state.UserInfo.brand === 0 ? 'SboBet': 'SboTop' : '';
        },
        IsEmbeddedInPage: state => {
            if (state.IsEmbedded && state.Layout !== 'modal') {
                return true;
            }
            return false;
        },
        IsInDDayPage: state => {
            if (state.IsEmbedded) {
                return true;
            }
            return false;
        },
    },
})
