export default {
    id_id: {
        messages: { 
            providerFilterTitle:"Lihat berdasarkan Provider",
            step1: "Putar <span style='color: yellow;'>permainan slot apa saja</span> untuk mengisi bar.",
            step2: "Kumpulkan <span style='color: yellow;'>3 Permata</span> dari seluruh Kotak Tunai untuk membuka <span style='color: yellow;'>Misi Tunai</span>!",
            step3: "<span style='color: yellow;'>Misi Tunai</span> akan diaktifkan setelah semua permata terkumpul.",
            gamesTitle: "Permainan yang memenuhi syarat",
            gamesSubTitle: "Mainkan permainan slot apa saja untuk memaksimalkan hadiah tunai harian Anda!",
            howToPlayTitle: "Cara Membuka Kotak Tunai",
            tutorialContent: "Buka halaman <span style='color: yellow;text-decoration: underline;'>Reward</span> untuk memeriksa hadiah Kotak Tunai harian Anda.",
            rebateHowToPlayTitle: "Cara Mendapatkan Hadiah Misi Tunai",
            rebateHowToPlaySubTitle: "Hadiah akan terus bertambah di setiap putaran!",
            rebateHowToPlayContent: "Hadiah Uang Tunai terus bertambah di <span style='color: yellow;'>setiap putaran</span>. Spin terus!",
            rebateTutorialContent: "Hadiah tunai akan dikreditkan ke akun Anda setelah waktu berakhir. Periksa hadiah harian Anda pada halaman <span style='color: yellow;'>Reward</span>.",
            tnc: "Syarat dan Ketentuan berlaku",
            rotateText: "Silakan putar perangkat Anda ke mode portrait untuk tampilkan lebih",
        }
    },
};
