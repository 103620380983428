<template lang="html">
    <svg class="svgIcon">
        <use :xlink:href="'#'+iconName"></use>
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        iconName: {
            type: String,
            default: function () {
                return "";
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.svgIcon {
  width: 1em;
  height: 1em;
  fill: #999
}
</style>