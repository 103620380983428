<template>
  <div class="" :class='["desktop", "eventContainer", "cashBox", isEmbeddedInPage ? "d-day" : ""]'>
    <EventKV v-if="!isEmbeddedInPage" :eventName="eventName" :lang="lang" :stage="stage" :view="view" :eventImages="eventImages"/>
    <div class="content-container">
      <div id="progressBarApp-EventContent"></div>
      <ProviderFilter/>
    </div>
    <Games :stage="stage" :isMobile="false" :view="view" :lang="lang" :cdnUrl="cdnUrl" :games="eventGameList"
            :resources="resources" :promotionType="promotionData.promotionType"
            :eventName="eventName"/>
    <HowToPlay :lang="lang" :promotionType="promotionData.promotionType" :device="'Desktop'" :resources="resources" :eventName="eventName"/>
    <div class="eventBgBottom"></div>
  </div>
</template>

<script>
import EventKV from "../components/EventKV.vue";
import Games from "../components/Games.vue";
import ProviderFilter from "../components/ProviderFilter.vue";
import HowToPlay from "../components/HowToPlay.vue";
import Axios from 'axios';
import HeaderHelper from "@/libraries/HeaderHelper";
import UrlHelper from "@/libraries/UrlHelper";
import moment from "moment/moment";
import GtmHelper from "@/components/utils/GtmHelper";
import { loadScripts } from "@/libraries/JsHelper";

export default {
  name: "Desktop",
  components: {
    HowToPlay,
    ProviderFilter,
    Games,
    EventKV,
  },
  mixins: [GtmHelper],
  data() {
    return {
      dailyMissionItem: {
        GAclassName: 'DailyMission',
        GAclassNameSwitch: 'SwitchAreaDailyMission',
        title: 'DailyMission',
        subTitle: '',
        show: false,
      },
      itemList: [
        {
          GAclassName: 'CurrentRanking',
          GAclassNameSwitch: 'SwitchAreaCurrentRanking',
          title: '',
          subTitle: '',
          show: false,
          turnoverWinners: [],
          betCountWinners: [],
          winAmountWinners: [],
        },
        {
          GAclassName: 'WinnerList',
          GAclassNameSwitch: 'SwitchAreaWinnerList',
          title: '',
          subTitle: '',
          show: false,
          turnoverWinners: [],
          betCountWinners: [],
          winAmountWinners: [],
        }
      ],
      eventId: null,
      img: {
        backgroundTop: `${require(`@/assets/images/Bg_top_desktop.png`)}`,
        backgroundBottom: `${require(`@/assets/images/Bg_bottom_desktop.png`)}`,
      },
      resources: null,
      eventType: "",
      isEmbeddedInPage: false
    }
  },
  props: {
    lang: {
      type: String,
      default: function () {
        return "en";
      }
    },
    stage: {
      type: String,
      default: function () {
        return "PrePromo";
      }
    },
    view: {
      type: String,
      default: function () {
        return "desktop";
      }
    },
    promotionData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    eventGameList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    imgPath: {
      type: String,
      default: function () {
        return '';
      }
    },
    eventImages: {
      type: Object,
      default: function () {
        return {};
      }
    },
    eventName: {
      type: String,
      default: function () {
        return '';
      }
    },
  },
  watch: {
    promotionData() {
      this.getWinners();
      this.eventType = this.$store.state.SelectedEventType;
    },
    resources() {
      this.dailyMissionItem.title = this.resources.dailyMissionTitle;
      this.itemList[0].title = this.resources.currentTankingTitle;
      this.itemList[1].title = this.resources.winnersTitle;
    }
  },
  methods: {
    ToggleClick(index) {
      this.itemList[index].show = !this.itemList[index].show;

      this.gtmDataLayer().pushToDataLayer('view', {
        ...this.getGtmEventData(),
        gtmLabel: `Promoevent_section_${index === 0 ? 'currentTankingTitle' : 'Winningranking'}`,
        gtmAction: 'View',
      });
    },
    ToggleClickDailyMission() {
      this.dailyMissionItem.show = !this.dailyMissionItem.show;

      this.gtmDataLayer().pushToDataLayer('view', {
        ...this.getGtmEventData(),
        gtmLabel: `Promoevent_section_${index === 0 ? 'currentTankingTitle' : 'Winningranking'}`,
        gtmAction: 'View',
      });
    },
    getWinners() {
      const vueData = this;
      //TODO extract service for winners
      const chaliuApiUrl = UrlHelper.getChaliuApiUrl();
      const apiUrl = new URL(`${chaliuApiUrl}/Event/${this.eventId}/winners`);
        if (!this.$store.getters.Is_Asi) {
        apiUrl.searchParams.set('brand', this.$store.state.Brand);
        apiUrl.searchParams.set('sboCurrency', this.$store.state.UserInfo.sboCurrency);
        apiUrl.searchParams.set('device', this.$store.getters.Get_Device);
      }
      Axios.get(apiUrl.href, {
        headers: HeaderHelper.getMultiPromotionHeaderOptions(this.$store.state.UserInfo.encryptedCustId)
      }).then((resp) => {
        vueData.winners = resp.data;

        if (resp.data.HistoryWinners == null) {
          vueData.itemList[1].turnoverWinners = null;
          vueData.itemList[1].betCountWinners = null;
          vueData.itemList[1].winAmountWinners = null;
        } else {
          vueData.itemList[1].turnoverWinners = vueData.winners.HistoryWinners.PromotionMatch.turnover;
          vueData.itemList[1].betCountWinners = vueData.winners.HistoryWinners.PromotionMatch.betCount;
          vueData.itemList[1].winAmountWinners = vueData.winners.HistoryWinners.PromotionMatch.winAmount;
          vueData.itemList[1].show = (vueData.stage === 'Winner');
        }

        if (resp.data.LiveWinners == null) {
          vueData.itemList[0].turnoverWinners = null;
          vueData.itemList[0].betCountWinners = null;
          vueData.itemList[0].winAmountWinners = null;
          vueData.itemList[0].subTitle = null;
        } else {
          vueData.itemList[0].turnoverWinners = vueData.winners.LiveWinners.PromotionMatch.turnover;
          vueData.itemList[0].betCountWinners = vueData.winners.LiveWinners.PromotionMatch.betCount;
          vueData.itemList[0].winAmountWinners = vueData.winners.LiveWinners.PromotionMatch.winAmount;
          vueData.itemList[0].show = (vueData.stage === 'Winner');

          const timezoneOffset = new Date().getTimezoneOffset() / -60;
          let timezoneString = timezoneOffset;
          if (timezoneOffset > 0) {
            timezoneString = `+${timezoneOffset}`;
          }

          const parseFormat = 'HH:mm:ss';
          const outputFormat = 'DD/MM/YYYY hh:mm A'; // ex: 17/10/2022 12:00 PM
          const startUtcTime = moment(vueData.winners.LiveWinners.startTime, parseFormat).add(timezoneOffset + 4,'hour');
          const endUtcTime = moment(vueData.winners.LiveWinners.endTime, parseFormat).add(timezoneOffset + 4,'hour');

          let subtitleType = '';
          if (vueData.itemList[0].turnoverWinners) {
              subtitleType = this.resources.turnoverTournamentText;
          } else if (vueData.itemList[0].betCountWinners) {
              subtitleType = this.resources.betCountTournamentText;
          } else if (vueData.itemList[0].winAmountWinners) {
              subtitleType = this.resources.winAmountTournamentText;
          }

          vueData.itemList[0].subTitle = `${subtitleType}<br>${startUtcTime.format(outputFormat)} - ${(endUtcTime.format(outputFormat))} (GMT${timezoneString})`;
        }
      });
    },winnerListEnable(item) {
      return (item.turnoverWinners != null && item.turnoverWinners.length > 0) || (item.betCountWinners != null && item.betCountWinners.length > 0) || (item.winAmountWinners != null && item.winAmountWinners.length > 0);
    },getResources() {
          return {
              step1: this.i18nConfig.t('messages.step1'),
              step2: this.i18nConfig.t('messages.step2'),
              step3: this.i18nConfig.t('messages.step3'),
              gamesTitle: this.i18nConfig.t('messages.gamesTitle'),
              gamesSubTitle: this.i18nConfig.t('messages.gamesSubTitle'),
              quickViewItem1: this.i18nConfig.t('messages.quickViewItem1'),
              quickViewItem2: this.i18nConfig.t('messages.quickViewItem2'),
              quickViewCheckMore: this.i18nConfig.t('messages.quickViewCheckMore'),
              quickViewCheckLess: this.i18nConfig.t('messages.quickViewCheckLess'),
              currentTankingTitle: this.i18nConfig.t('messages.currentTankingTitle'),
              rankingNumber: this.i18nConfig.t('messages.rankingNumber'),
              rankingUserId: this.i18nConfig.t('messages.rankingUserId'),
              betAmount: this.i18nConfig.t('messages.betAmount'),
              spinNo: this.i18nConfig.t('messages.spinNo'),
              winAmount: this.i18nConfig.t('messages.winAmount'),
              rankingPrize: this.i18nConfig.t('messages.rankingPrize'),
              turnoverTournamentText: this.i18nConfig.t('messages.turnoverTournamentText'),
              betCountTournamentText: this.i18nConfig.t('messages.betCountTournamentText'),
              winAmountTournamentText: this.i18nConfig.t('messages.winAmountTournamentText'),
              rankingTurnoverTitle: this.i18nConfig.t('messages.rankingTurnoverTitle'),
              rankingSpinTitle: this.i18nConfig.t('messages.rankingSpinTitle'),
              rankingWinAmountTitle: this.i18nConfig.t('messages.rankingWinAmountTitle'),
              winnersTitle: this.i18nConfig.t('messages.winnersTitle'),
              winnersTurnoverTitle: this.i18nConfig.t('messages.winnersTurnoverTitle'),
              winnersSpinTitle: this.i18nConfig.t('messages.winnersSpinTitle'),
              winnersWinAmountTitle: this.i18nConfig.t('messages.winnersWinAmountTitle'),
              turnoverSubTitle: this.i18nConfig.t('messages.turnoverSubTitle'),
              spinSubTitle: this.i18nConfig.t('messages.spinSubTitle'),
              winAmountSubTitle: this.i18nConfig.t('messages.winAmountSubTitle'),
              unitSpin: this.i18nConfig.t('messages.unitSpin'),
              user: this.i18nConfig.t('messages.user'),
              unitProcess: this.i18nConfig.t('messages.unitProcess'),
              howToPlayTitle: this.i18nConfig.t('messages.howToPlayTitle'),
              rankingWinner: this.i18nConfig.t('messages.rankingWinner'),
              luckydrawWinner: this.i18nConfig.t('messages.luckydrawWinner'),
              countdownTitleStart: this.i18nConfig.t('messages.countdownTitleStart'),
              countdownTitleStartEnd: this.i18nConfig.t('messages.countdownTitleStartEnd'),
              countdownTitleStartLast: this.i18nConfig.t('messages.countdownTitleStartLast'),
              countdownTitleStartEndLast: this.i18nConfig.t('messages.countdownTitleStartEndLast'),
              countdownTitleEnd: this.i18nConfig.t('messages.countdownTitleEnd'),
              countdownTitleFinish: this.i18nConfig.t('messages.countdownTitleFinish'),
              tnc: this.i18nConfig.t('messages.tnc'),
              rotateText: this.i18nConfig.t('messages.rotateText'),
              dailyMissionTitle: this.i18nConfig.t('messages.dailyMissionTitle'),
              tutorialContent: this.i18nConfig.t('messages.tutorialContent'),
              rebateHowToPlayTitle: this.i18nConfig.t('messages.rebateHowToPlayTitle'),
              rebateHowToPlaySubTitle: this.i18nConfig.t('messages.rebateHowToPlaySubTitle'),
              rebateHowToPlayContent: this.i18nConfig.t('messages.rebateHowToPlayContent'),
              rebateTutorialContent: this.i18nConfig.t('messages.rebateTutorialContent')
          };
      },
  },
  computed: {
    i18nConfig() {
      return this.$i18n;
    },
    cdnUrl() {
      return process.env.VUE_APP_CDN_URL;
    },
  },
  created() {
    this.eventId = this.$store.state.SelectedEventId;
    this.isEmbeddedInPage = this.$store.getters.IsEmbeddedInPage;

    this.resources = this.getResources();
    // eslint-disable-next-line no-console
  },
  mounted() {
    this.resources = this.getResources();
    loadScripts(`${process.env.VUE_APP_THEIA_JS}/progress-bar-load.js`)
    .then(() => {
      window.dispatchEvent(new CustomEvent('cashbox-event-page-ready-to-mount'));
    });
  },
}

</script>

<style lang="scss">
@import "~@/scss/views/desktop";
</style>
