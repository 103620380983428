import Cookies from "js-cookie";

export default {
    getMultiPromotionHeaderOptions(encryptedCustId){
        let headerOptions = {};
        if (Cookies.get('IJ_promotion_customer')) {
            headerOptions = {
                customer: Cookies.get('IJ_promotion_customer'),
            }
        }
        else
        {
            headerOptions = {
                encryptedCustId: encryptedCustId,
            }
        }
        
        return headerOptions;
    }
}
