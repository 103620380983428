<template>
  <div class="areaTitle">
    <div class="title-wrap">
      <img class="section-deco right" src="../assets/images/cashbox-euro/deco-fire.png">
      <div class="areaTitle-wrap">
          <img class="title-deco left" src="../assets/images/cashbox-euro/title-coin-l.png">
          <p class="areaTitle-title">{{title}}</p>
          <img class="title-deco right" src="../assets/images/cashbox-euro/title-coin-r.png">
      </div>
      <img class="section-deco left" src="../assets/images/cashbox-euro/deco-fire.png">
    </div>
    <p v-if="subTitle!=''" v-html="subTitle"></p>
  </div>
</template>

<script>
export default {
  name: "PopupButton",
  props: {
    title: {
      type: String,
      default: function() {
        return "AreaTitle";
      }
    },
    subTitle: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/colors.scss";


.areaTitle {
  position: relative;
  text-align: center;
  padding: 12px 0;
  z-index: 2;
  .title-wrap{
    position: relative;
    width: fit-content;
    margin: 40px auto 0px;
    .section-deco{
      position: absolute;
      left: -125%;
      top: -50%;
      height: 170%;
      &.right{
        left: auto;
        right: -125%;
        transform: scaleX(-1);
      }
    }
  }
  .areaTitle-wrap{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      z-index: 2;
      padding: 10px 45px;
      border-radius: 15px;
      background-color: #143CDB;
      border: 4px solid #1CA75E;
      .title-deco{
        position: absolute;
        left: -15%;
        top: -20%;
        height: 140%;
        &.right{
          left: auto;
          right: -15%;
        }
      }
      .areaTitle-title{
        color: #FFE13A;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5em;
        margin: 0px;
        z-index: 2;
    }
  }
  p {
    font-size: 10px;
    line-height: 18px;
    color: $eventTemplate_SubTitle;
    margin-top: 20px;
  }
}

@media (max-device-width: 768px) {
  .areaTitle {
    .areaTitle-wrap{
        .areaTitle-bg{
          width: 90%;
        }
        .areaTitle-title{
          font-size: 16px;
        }
    }
  }
}
@media (max-device-width: 450px) {
  .areaTitle{
    .title-wrap{
      .section-deco{
        left: -70%;
        &.right{
          right: -70%;
        }
      }
    }
  }
} 
</style>
