<template>
  <div class="mobileLandscape eventContainer cashBox">
      <div class="rotate-msg">
        <img class="rotate-icon" :src="`/images/luckydraw/rotate.svg`">
        <p>{{resources.rotateText}}</p>
      </div>
    </div>
</template>

<script>

export default {
  name: "MobileLandscape",
  components: {
  },
  props: {
    lang:{
      type: String,
      default: function() {
        return "en";
      }
    },
    stage: {
      type: String,
      default: function() {
        return "PrePromo";
      }
    },
    view: {
      type: String,
      default: function() {
        return "mobilePortraits";
      }
    },
  },
  data: function() {
    return {
      resources: null,
    };
  },
  mounted(){
      this.resources = this.getResources();
  },
  computed: {
    i18nConfig(){
                return this.$i18n;
            }
  },
  created() {
    this.resources = this.getResources();
  },
  methods: {
      getResources() {
          return {
              step1: this.i18nConfig.t('messages.step1'),
              step2: this.i18nConfig.t('messages.step2'),
              step3: this.i18nConfig.t('messages.step3'),
              gamesTitle: this.i18nConfig.t('messages.gamesTitle'),
              gamesSubTitle: this.i18nConfig.t('messages.gamesSubTitle'),
              quickViewItem1: this.i18nConfig.t('messages.quickViewItem1'),
              quickViewItem2: this.i18nConfig.t('messages.quickViewItem2'),
              quickViewCheckMore: this.i18nConfig.t('messages.quickViewCheckMore'),
              quickViewCheckLess: this.i18nConfig.t('messages.quickViewCheckLess'),
              currentTankingTitle: this.i18nConfig.t('messages.currentTankingTitle'),
              rankingNumber: this.i18nConfig.t('messages.rankingNumber'),
              rankingUserId: this.i18nConfig.t('messages.rankingUserId'),
              betAmount: this.i18nConfig.t('messages.betAmount'),
              spinNo: this.i18nConfig.t('messages.spinNo'),
              winAmount: this.i18nConfig.t('messages.winAmount'),
              rankingPrize: this.i18nConfig.t('messages.rankingPrize'),
              turnoverTournamentText: this.i18nConfig.t('messages.turnoverTournamentText'),
              betCountTournamentText: this.i18nConfig.t('messages.betCountTournamentText'),
              winAmountTournamentText: this.i18nConfig.t('messages.winAmountTournamentText'),
              rankingTurnoverTitle: this.i18nConfig.t('messages.rankingTurnoverTitle'),
              rankingSpinTitle: this.i18nConfig.t('messages.rankingSpinTitle'),
              rankingWinAmountTitle: this.i18nConfig.t('messages.rankingWinAmountTitle'),
              winnersTitle: this.i18nConfig.t('messages.winnersTitle'),
              winnersTurnoverTitle: this.i18nConfig.t('messages.winnersTurnoverTitle'),
              winnersSpinTitle: this.i18nConfig.t('messages.winnersSpinTitle'),
              winnersWinAmountTitle: this.i18nConfig.t('messages.winnersWinAmountTitle'),
              turnoverSubTitle: this.i18nConfig.t('messages.turnoverSubTitle'),
              spinSubTitle: this.i18nConfig.t('messages.spinSubTitle'),
              winAmountSubTitle: this.i18nConfig.t('messages.winAmountSubTitle'),
              unitSpin: this.i18nConfig.t('messages.unitSpin'),
              user: this.i18nConfig.t('messages.user'),
              unitProcess: this.i18nConfig.t('messages.unitProcess'),
              howToPlayTitle: this.i18nConfig.t('messages.howToPlayTitle'),
              rankingWinner: this.i18nConfig.t('messages.rankingWinner'),
              luckydrawWinner: this.i18nConfig.t('messages.luckydrawWinner'),
              countdownTitleStart: this.i18nConfig.t('messages.countdownTitleStart'),
              countdownTitleStartEnd: this.i18nConfig.t('messages.countdownTitleStartEnd'),
              countdownTitleStartLast: this.i18nConfig.t('messages.countdownTitleStartLast'),
              countdownTitleStartEndLast: this.i18nConfig.t('messages.countdownTitleStartEndLast'),
              countdownTitleEnd: this.i18nConfig.t('messages.countdownTitleEnd'),
              countdownTitleFinish: this.i18nConfig.t('messages.countdownTitleFinish'),
              tnc: this.i18nConfig.t('messages.tnc'),
              rotateText: this.i18nConfig.t('messages.rotateText'),
              dailyMissionTitle: this.i18nConfig.t('messages.dailyMissionTitle'),
              tutorialContent: this.i18nConfig.t('messages.tutorialContent'),
              rebateHowToPlayTitle: this.i18nConfig.t('messages.rebateHowToPlayTitle'),
              rebateHowToPlaySubTitle: this.i18nConfig.t('messages.rebateHowToPlaySubTitle'),
              rebateHowToPlayContent: this.i18nConfig.t('messages.rebateHowToPlayContent'),
              rebateTutorialContent: this.i18nConfig.t('messages.rebateTutorialContent')
          };
      },
  },
};
</script>

<style lang="scss">
@import "~@/scss/views/mobileLandscape";
</style>
