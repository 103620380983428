import {createGtm, DebugLogPlugin} from '@injoi-utils/vue2-gtm';
import UserAgentHelper from '@/libraries/UserAgentHelper';

const plugins = (process.env.VUE_APP_ENV === 'development') ? [DebugLogPlugin()] : [];

export default createGtm({
    // decide will event being sent to GTM container (default: true)
    isEnabled: true,
    // GTM container ID
    gtmId:  UserAgentHelper.isMobile() ? 'GTM-KSPQ4C' : 'GTM-PC5SK8',
    // Optional, specific the dataLayer name
    // Hint: you should always do this to prevent conflict with other departments
    dataLayerName: 'dataLayer',
    plugins: plugins,
});
