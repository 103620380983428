import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessages from './enMessages';
import th_thMessages from './th_thMessages';
import id_idMessages from './id_idMessages';
import zh_cnMessages from './zh_cnMessages';
import ko_krMessages from './ko_krMessages';
import ja_jpMessages from './ja_jpMessages';
import my_mmMessages from './my_mmMessages';
import vi_vnMessages from './vi_vnMessages';
import pt_brMessages from './pt_brMessages';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', //th_th, id_id, zh_cn, en, ko_kr, ja_jp
    fallbackLocale: 'en',
    messages: {
      ...enMessages,
      ...th_thMessages,
      ...id_idMessages,
      ...zh_cnMessages,
      ...ko_krMessages,
      ...ja_jpMessages,
      ...my_mmMessages,
      ...vi_vnMessages,
      ...pt_brMessages
    }
});

export default i18n;