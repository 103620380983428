import {rest} from 'msw';

export const resApiHandler = [
    rest.get('/asi/getIJToken', (_, res, ctx) => res(
        ctx.status(200),
        ctx.body('testIJToken'),
    )),
    rest.get('/asi/UserInfo', (_, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            "brand": 0,
            "isB2C2CMember": false,
            "isB2CMember": false,
            "encryptedCustId": "jiDzSXQzr7k",
            "lang": "EN",
            "isIomMember": false,
            "gameReachLimit": 0,
            "liveDrawReachLimit": 0,
            "countryCode": "ID",
            "currency": "IDR",
            "sboCurrency": "Pp",
            "trackingId": "sdT9GbEsI2dCBWreTwfnS"
        }),
    )),
    rest.get('/asi/getCdnInfo', (_, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            "CdnImgUrl": "http://tjjeya2c7y-games.sbotry.com/",
            "CdnTxtUrl": "http://tjjeya2c7y-games.sbotry.com/",
            "JsVersion": "20230329",
            "ImgVersion": "20230329"
        }),
    )),
    rest.get('/Event/EventGameList/547', (_, res, ctx) => res(
        ctx.status(200),
        ctx.json([{
            "imageName": "",
            "gameCode": 18017109,
            "gameName": "Prosperity Fortune Tree",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017108,
            "gameName": "Totem Wonders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017107,
            "gameName": "Wild Bounty Showdown",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017106,
            "gameName": "Wild Coaster",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017105,
            "gameName": "Legend of Perseus",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017104,
            "gameName": "Speed Winner",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017103,
            "gameName": "Lucky Piggy",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017102,
            "gameName": "Win Win Fish Prawn Crab",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017101,
            "gameName": "Battleground Royale",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017100,
            "gameName": "The Queen's Banquet",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801799,
            "gameName": "Rooster Rumble",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801798,
            "gameName": "Butterfly Blossom",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801797,
            "gameName": "Destiny of Sun and Moon",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801796,
            "gameName": "Garuda Gems",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801795,
            "gameName": "Fortune Tiger",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801794,
            "gameName": "Oriental Prosperity",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801793,
            "gameName": "Mask Carnival",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801792,
            "gameName": "Cocktail Nights",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801791,
            "gameName": "Emoji Riches",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801787,
            "gameName": "Buffalo Win",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801786,
            "gameName": "Supermarket Spree",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801785,
            "gameName": "Raider Jane's Crypt of Fortune",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801784,
            "gameName": "Groundhog Harvest",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801783,
            "gameName": "Mermaid Riches",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801782,
            "gameName": "Jurassic Kingdom",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801781,
            "gameName": "Sushi Oishi",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801780,
            "gameName": "Rise of Apollo",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801779,
            "gameName": "Heist Stakes",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801778,
            "gameName": "Ways of the Qilin",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801777,
            "gameName": "Wild Bandito",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801776,
            "gameName": "Candy Bonanza",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801775,
            "gameName": "Majestic Treasures",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801774,
            "gameName": "Crypto Gold",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801773,
            "gameName": "Opera Dynasty",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801772,
            "gameName": "Guardians of Ice and Fire",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801771,
            "gameName": "Galactic Gems",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801770,
            "gameName": "Jack Frost's Winter",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801769,
            "gameName": "Jewels of Prosperity",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801768,
            "gameName": "Dreams of Macau",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801767,
            "gameName": "Bikini Paradise",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801766,
            "gameName": "Reel Love",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801765,
            "gameName": "Gem Saviour Sword",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801764,
            "gameName": "Santa's Gift Rush",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801763,
            "gameName": "Wizdom Wonders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801762,
            "gameName": "Secrets of Cleopatra",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801761,
            "gameName": "Vampire's Charm",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801760,
            "gameName": "Queen of Bounty",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801759,
            "gameName": "Thai River Wonders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801758,
            "gameName": "Bali Vacation",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801757,
            "gameName": "Treasures of Aztec",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801756,
            "gameName": "Lucky Neko",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801755,
            "gameName": "Fortune Ox",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801754,
            "gameName": "Shaolin Soccer",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801753,
            "gameName": "Genie's 3 Wishes",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801752,
            "gameName": "Circus Delight",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801751,
            "gameName": "Restaurant Craze",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801750,
            "gameName": "Wild Fireworks",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801749,
            "gameName": "Dim Sum Mania",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801745,
            "gameName": "Ganesha Fortune",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801744,
            "gameName": "Dragon Hatch",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801743,
            "gameName": "Egypt's Book of Mystery",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801741,
            "gameName": "Candy Burst",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801740,
            "gameName": "Caishen Wins",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801739,
            "gameName": "Gem Saviour Conquest",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801725,
            "gameName": "Leprechaun Riches",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801720,
            "gameName": "Win Win Won",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801706,
            "gameName": "Legend of Hou Yi",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801724,
            "gameName": "Fortune Gods JP",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801723,
            "gameName": "Dragon Legend JP",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801722,
            "gameName": "Prosperity Lion JP",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801721,
            "gameName": "Honey Trap of Diao Chan JP",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801711,
            "gameName": "Ganesha Gold",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801710,
            "gameName": "Jungle Delight",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801704,
            "gameName": "Hotpot",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801701,
            "gameName": "Summon and Conquer",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801703,
            "gameName": "Piggy Gold",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801705,
            "gameName": "Fortune Gods",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801707,
            "gameName": "Three Monkeys ",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801708,
            "gameName": "Prosperity Lion",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801709,
            "gameName": "Tree of Fortune",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801712,
            "gameName": "Gem Saviour",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801713,
            "gameName": "Hood vs Wolf",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801714,
            "gameName": "Mr Hallow Win",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801715,
            "gameName": "Medusa II",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801716,
            "gameName": "Medusa",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801717,
            "gameName": "Plushie Frenzy",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801718,
            "gameName": "Hip Hop Panda",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801719,
            "gameName": "Symbols of Egypt",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801788,
            "gameName": "Legendary Monkey King",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801789,
            "gameName": "Spirited Wonders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 1801790,
            "gameName": "Farm Invaders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }]),
    )),
    rest.get('Event/EventCategoryGameList/ProgressBar/1', (_, res, ctx) => res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json([{
            "imageName": "",
            "gameCode": 18017109,
            "gameName": "Prosperity Fortune Tree",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }, {
            "imageName": "",
            "gameCode": 18017108,
            "gameName": "Totem Wonders",
            "showIOMAlert": false,
            "isHotGame": false,
            "serviceProvider": "PocketGames"
        }]),
    )),
    // rest.get('Event', (_, res, ctx) => res(
    //     ctx.status(200),
    //     ctx.json({"eventName":"wc2","stage":"Promo","countDownDate":"2023-11-22T22:59:59","lang":"en","promotionType":"InHouse","eventImages":{"desktopEventImagePath":"//img-1-61.sbotry.com//image/banners/event_547/GAME_EVENT_PAGE_DESKTOP/MNL_EN_MYR-1666771114501.jpg","mobileEventImagePath":"//img-1-61.sbotry.com//image/banners/event_547/GAME_EVENT_PAGE_MOBILE/MNL_EN_MYR-1666771115005.jpg","bannerName":"Event_wc2"}}),
    // )),
    rest.get('/event/EventCategories/ProgressBar/1', (_, res, ctx) => res(
        ctx.status(200),
        ctx.json([
            {
                "name": "Eurasian Gaming",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/EurasianGaming.png",
                "id": "EurasianGaming"
            },
            {
                "name": "Play'n Go",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "PlaynGo"
            },
            {
                "name": "1",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "1"
            },
            {
                "name": "2",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "2"
            },{
                "name": "3",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "3"
            },{
                "name": "4",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "4"
            },{
                "name": "5",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "5"
            },{
                "name": "7",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "7"
            },{
                "name": "6",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "6"
            },{
                "name": "8",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "8"
            },{
                "name": "9",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "9"
            },{
                "name": "10",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/PlaynGo.png",
                "id": "10"
            },
            {
                "name": "JDB",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/JDB.png",
                "id": "JDB"
            },
            {
                "name": "Live22",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/Live22.png",
                "id": "Live22"
            },
            {
                "name": "Funky Games",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/FunkyGames.png",
                "id": "FunkyGames"
            },
            {
                "name": "SBO Games",
                "isSuspended": false,
                "iconPath": "http://tjjeya2c7y-games.sbotry.com//images/base/provider-logo/SBOGames.png",
                "id": "SBOGames"
            }
        ]),
    ))


];
