import UAParser from 'ua-parser-js';

const parser = new UAParser(); 

export default {
    isSafari(){
        const browser = parser.getBrowser().name;
        if (browser === undefined) {
            return false;
        }

        return browser.includes('Safari');
    },
    isMobile(){
        return parser.getDevice().type === 'mobile';
    }
};
