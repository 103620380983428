export default {
    zh_cn: {
        messages: { 
            providerFilterTitle:"按游戏商筛选",
            step1: "在<span style='color: yellow;'>任何老虎机游戏</span>中旋转以填满进度条。",
            step2: "通过从聚宝盆中收集<span style='color: yellow;'>3颗宝石</span>以解锁<span style='color: yellow;'>现金任务</span>！",
            step3: "一旦齐聚所有宝石，<span style='color: yellow;'>现金任务</span>将即刻启动。",
            gamesTitle: "符合资格的游戏",
            gamesSubTitle: "在任何老虎机游戏中旋转，尽情赢取每日现金奖励！",
            howToPlayTitle: "如何解锁聚宝盆？",
            tutorialContent: "前往<span style='color: yellow;text-decoration: underline;'>奖励</span>页面查看您每天的聚宝盆收益。",
            rebateHowToPlayTitle: "如何开启现金任务",
            rebateHowToPlaySubTitle: "奖励随著每次旋转增长！",
            rebateHowToPlayContent: "<span style='color: yellow;'>每次旋转</span>都为现金任务的奖励增添力量。继续旋转吧！",
            rebateTutorialContent: "倒计时结束后，现金奖励将存入您的账户。请在 <span style='color: yellow;'>奖励</span>页面查看您的每日收益。",
            tnc: "法规和条例适用",
            rotateText: "请把设备转至横向模式以查看更多。",
        }
    },
};