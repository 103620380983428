<template>
    <div id="howToPlay" class="howToPlay">
        <div class="howToPlay-title-wrap">
            <img class="title-deco left" src="../assets/images/cashbox-euro/title-coin-l.png">
            <p v-if="!isRebateModeUnlocked" class="howToPlay-title"> {{howToPlayTitle}} </p>
            <p v-else class="howToPlay-title"> {{rebateHowToPlayTitle}} </p>
            <img class="title-deco right" src="../assets/images/cashbox-euro/title-coin-r.png">
        </div>
        <div class="howToPlay-list-wrap">
            <div class="howToPlay-list-content">
                <img class="deco-top left" src="../assets/images/cashbox-euro/deco-howitwork.png">
                <img class="deco-top right" src="../assets/images/cashbox-euro/deco-howitwork.png">
                <div v-if="!isRebateModeUnlocked" class="default-howToPlay-list">
                    <li v-for="(item,index) in steps" :key="index" class="howToPlay-item">
                        <img class="prizesImg" :src="require(`@/assets/images/stepIcons/step${index+1}.png`)"
                            v-gtm:event="'games'"
                            v-gtm:gtmAction="'Click'"
                            v-gtm:gtmLabel="'Promoevent_Section_Howitsworksclick'"
                            v-gtm:gtmCategory="'Promoevent'"
                            v-gtm:source = "getGtmDataSource()"
                            v-gtm:promoeventname ="eventName"
                            v-gtm:@click
                            @click="GetTncLink">
                        <div class="howToPlay-item-info" :class="[{ 'mymm': isMymm }]"
                            v-gtm:event="'games'"
                            v-gtm:gtmAction="'Click'"
                            v-gtm:gtmLabel="'Promoevent_Section_Howitsworksclick'"
                            v-gtm:gtmCategory="'Promoevent'"
                            v-gtm:source = "getGtmDataSource()"
                            v-gtm:promoeventname ="eventName"
                            v-gtm:@click
                            @click="GetTncLink"  v-html="item.text"></div>
                        <span class="arrow"><img :src="require(`@/assets/images/stepIcons/step-arrow.png`)"
                            v-gtm:event="'games'"
                            v-gtm:gtmAction="'Click'"
                            v-gtm:gtmLabel="'Promoevent_Section_Howitsworksclick'"
                            v-gtm:gtmCategory="'Promoevent'"
                            v-gtm:source = "getGtmDataSource()"
                            v-gtm:promoeventname ="eventName"
                            v-gtm:@click
                            @click="GetTncLink"></span>
                    </li>
                </div>
                <div v-else class="rebate-howToPlay-list">
                    <img class="rebate-howToPlay-icon" src="../assets/images/tutorial/rebateIcon/EN.png">
                    <div class="rebate-howToPlay-content">
                      <p class="rebate-howToPlay-title"> {{rebateHowToPlaySubTitle}}</p>
                        <p class="rebate-howToPlay-p" v-html="rebateHowToPlayContent"/>
                    </div>
                </div>
                <div class="tutorial-content" v-if="!isInDDayPage">
                    <img class="tutorial-content-icon" src="../assets/images/tutorial/treasureBag.png">
                    <p v-if="!isRebateModeUnlocked" class="tutorial-content-p" v-html="tutorialContent" v-on:click="goToRewardHistory" />
                    <p v-else class="tutorial-content-p" v-html="rebateTutorialContent" v-on:click="goToRewardHistory" />
                </div>
            </div>
            <img class="ribbon-deco" src="../assets/images/cashbox-euro/bg-ribbon.svg">
        </div>
        <div class="TandC">
          <a class="TandC" 
             v-gtm:event="'games'"
             v-gtm:gtmAction="'Click'"
             v-gtm:gtmLabel="'Promoevent_Section_tncclick'"
             v-gtm:gtmCategory="'PromoLobby'"
             v-gtm:source = "getGtmDataSource()"
             v-gtm:promoeventname ="eventName"
             v-gtm:@click
             @click="GetTncLink"
             target="_blank"> {{tnc}} </a></div>
        <div class="bottom-deco-wrap">
            <img class="bottom-deco left" src="../assets/images/cashbox-euro/deco-bottom.png">
            <img class="bottom-deco right" src="../assets/images/cashbox-euro/deco-bottom.png">
        </div>
    </div>
</template>

<script>
    import AreaTitle from "@/components/AreaTitle.vue";
    import Axios from 'axios';
    import HeaderHelper from "@/libraries/HeaderHelper";
    import UrlHelper from "@/libraries/UrlHelper";
    import GtmHelper from "@/components/utils/GtmHelper";
    import i18n from "@/lang/i18n";

    export default {
        name: "HowToPlay",
        components: {
            AreaTitle
        },
        mixins: [GtmHelper],
        props: {
            lang: {
                type: String,
                default: function () {
                    return "en";
                }
            },
            promotionType: {
                type: String,
                default: function () {
                    return "";
                }
            },
            device:{
                type: String,
                default: function(){
                    return "Mobile";
                }
            },
            resources: {
              type: Object,
              default: function () {
                return {};
              }
            },
            eventName: {
              type: String,
            },
        },
        data: function () {
            return {
                steps: [
                    {
                        GAsteps: 'HowItWorks.Play.Play',
                        text: "",
                    },
                    {
                        GAsteps: 'HowItWorks.Play.Collect',
                        text: "",
                    },
                    {
                        GAsteps: 'HowItWorks.Play.Win',
                        text: ""
                    },
                ],
                howToPlayTitle: "",
                tnc: "", 
                tutorialContent: "",
                rebateHowToPlayTitle:"",
                rebateHowToPlaySubTitle:"",
                rebateHowToPlayContent:"",
                isRebateModeUnlocked: false
            }
        },
        watch: {
            resources: function() {
                this.steps[0].text = this.resources.step1;
                this.steps[1].text = this.resources.step2;
                this.steps[2].text = this.resources.step3;
                this.howToPlayTitle = this.resources.howToPlayTitle;
                this.tnc = this.resources.tnc;
                this.tutorialContent = this.resources.tutorialContent;
                this.rebateHowToPlayTitle = this.resources.rebateHowToPlayTitle;
                this.rebateHowToPlaySubTitle = this.resources.rebateHowToPlaySubTitle;
                this.rebateHowToPlayContent = this.resources.rebateHowToPlayContent;
                this.rebateTutorialContent = this.resources.rebateTutorialContent;
            }
        },
        methods: {
            GetImgFromCDN(imgUrl){
                return `${process.env.VUE_APP_CDN_URL}${imgUrl}?ver=${this.promotionType}`;
            },
            GetTncLink() {
                const chaliuApiUrl = UrlHelper.getChaliuApiUrl();
                const url = new URL(`${chaliuApiUrl}/event/${this.$store.state.SelectedEventType}/${this.$store.state.SelectedEventId}/tnc`);
                if (!this.$store.getters.Is_Asi) {
                    url.searchParams.set('device', this.$store.getters.Get_Device);
                    url.searchParams.set('brand', this.$store.state.Brand);
                    url.searchParams.set('sboCurrency', this.$store.state.UserInfo.sboCurrency);
                }else{
                    url.searchParams.set('brand', this.$store.getters.GetBrandFromUserInfo);
                }
                url.searchParams.set('lang', this.$store.state.UserInfo.lang);

                Axios.get(url.href, {
                    headers: HeaderHelper.getMultiPromotionHeaderOptions(this.$store.state.UserInfo.encryptedCustId)
                })
                .then((resp) => {
                    if(this.$store.state.IsOnApp){
                        const tncUrl = new URL(resp.data);
                        tncUrl.searchParams.set('openExternalBrowser', 1);
                        window.parent.postMessage(JSON.stringify({
                          topic: 'wc_leaderboard_app_open_tnc',
                          data: {
                            url: tncUrl,
                          }
                        }), '*');
                      if (!this.isInDDayPage) {
                        window.location.href = tncUrl;
                      }
                    }else{
                        window.open(resp.data);
                    }
                })
            },
          getDataSource(){
              return GtmResourceHelper.getDataSource();
          },
          goToRewardHistory(){
            const teraHost = UrlHelper.getTeraUrl();
            if (this.$store.state.IsOnApp) {
                window.location.href = `${teraHost}cashbox-for-app`;
            }
            else if (this.isMobile) {
                window.location.href = `${teraHost}cashbox`;
            } else {
                window.dispatchEvent(new CustomEvent('platform:open-memberCenter', { detail: 'Statement' }));
            }
        },
        },
      computed: {
        isMymm() {
          return i18n.locale === 'my_mm';
        },
        isMobile(){
            return this.$store.state.IsMobileDevice;
        },
        isInDDayPage(){
            return this.$store.getters.IsInDDayPage;
        },
      },
      mounted() {
        window.addEventListener(
            "message",
            (event) => {
              if (event.data === 'unlockRebateMode') {
                  this.isRebateModeUnlocked = true;
              }
            },
            false,
        );
      }
    };
</script>


<style lang="scss" scoped>
@import "../scss/base/colors.scss";
.howToPlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 1rem 1rem 20px;
    z-index: 2;
    background: url(../assets/images/cashbox-euro/bg-redline.svg) top right no-repeat;
    background-size: auto 100%;
    .bottom-deco-wrap{
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .bottom-deco{
            width: 20%;
            &.right{
                transform: scaleX(-1);
            }
        }
    }
    .howToPlay-title-wrap{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: #143CDB;
        border: 4px solid #1CA75E;
        border-radius: 15px;
        padding: 10px 45px;
        margin: 0px 0px 20px;
        .title-deco{
            position: absolute;
            left: -15%;
            top: -20%;
            height: 140%;
            &.right{
                left: auto;
                right: -15%;
            }
        }
        .howToPlay-title{
            color: #FFE13A;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.5em;
            z-index: 2;
            margin: 0px;
        }
    }
    
    &-list-wrap{
        margin-top: -40px;
        border-radius: 20px;
        color: #fff;
        background: #0C34A0;
        position: relative;
        width: 100%;
        max-width: 1300px;
        .ribbon-deco{
            position: absolute;
            top: -36%;
            left: -10%;
            z-index: -1;
            transform: rotate(-10deg);
        }
        .howToPlay-list-content{
            padding: 45px 30px 10px 30px;
            border-radius: 15px;
            background-color: #0C34A0;
            border: 4px solid #1CA75E;
            position: relative;
            .deco-top{
                position: absolute;
                left: 5px;
                top: -48px;
                height: 44px;
                &.right{
                    left: auto;
                    right: 5px;
                    transform: scaleX(-1);
                }
            }
            .default-howToPlay-list {
                display: flex;
                justify-content: center;
                flex-wrap: nowrap;
                flex-direction: row;
                border-bottom: 2px dashed #979797;
                margin: 6px;
                .howToPlay-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    width: 250px;
                    margin: 5px;

                    &:last-child .arrow {
                        display: none;
                    }

                    .prizesImg {
                        width: 40%;
                        max-width: 140px;
                    }
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: -10px;
                        transform: translate(50%,-50%);
                    }

                    .howToPlay-item-info {
                        color: $howToPlay_itemInfo;
                        flex-grow: 1;
                        padding: 10px;
                        font-size: 0.9rem;
                        line-height: 1.2rem;
                        text-align: center;
                    }
                    .mymm{
                        line-height: 1.6rem;
                    }
                }
            }

            .rebate-howToPlay-list{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-bottom: 2px dashed #979797;
                .rebate-howToPlay-icon{
                    width: 25%;
                    padding-right: 20px
                }

                .rebate-howToPlay-content{
                    display: flex;
                    flex-direction: column;
                    .rebate-howToPlay-title{
                        font-weight: bold;
                        font-size: 24px;
                        margin: 5px 0;
                        .focus {
                            color: #FFFF00;
                        }
                    }
                    .rebate-howToPlay-p{
                        font-size: 14px;
                        margin: 5px 0;
                    }
                }
                
            }
            .tutorial-content{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 0.9rem;
                margin: 10px;
                .tutorial-content-icon{
                    width: 10%;
                    padding-right: 20px;
                }
                .tutorial-content-p{
                    font-size: 14px;
                }
            }
        }

        
    }
}

@media (max-device-width: 768px) {
    .howToPlay {
        padding: 12px;
        .howToPlay-title-wrap{
            .howToPlay-bg{
                width: 90%;
            }
            .howToPlay-title{
                font-size: 16px;
            }
        }
        .bottom-deco-wrap{
            z-index: 1;
            .bottom-deco{
                width: 40%;
            }
        }
        &-list-wrap{
            width: 100%;
            margin-top: -25px;
            .ribbon-deco{
                width: 40%;
                top: -20%;
            }
            .howToPlay-list-content{
                padding: 30px 10px 10px 10px;
                .default-howToPlay-list {
                    flex-direction: column;
                    .howToPlay-item {
                        width: 100%;
                        flex-direction: row;
                        .arrow {
                            display: none;
                        }
                        .howToPlay-item-info {
                            font-size: 12px;
                            padding:0;
                            text-align: left;
                        }
                    }
                }
                .rebate-howToPlay-list{
                    flex-direction: column;
                    text-align: center;
                    .rebate-howToPlay-icon{
                        width: 70%;
                        padding-right:0;
                    }
                    .rebate-howToPlay-content{
                        margin-bottom: 10px;
                        .rebate-howToPlay-title{
                            font-size: 16px;
                        }
                        .rebate-howToPlay-p{
                            font-size: 12px;
                        }
                    }
                    
                }
                .tutorial-content{
                    margin: 10px 0;
                    .tutorial-content-icon{
                        width: 30%;
                        max-width: 100px;
                    }
                    .tutorial-content-p{
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
}
</style>
