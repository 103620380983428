<script>
import gtmInstance from "@/libraries/gtmInstance";

export default {
  name: 'GtmHelper',
  methods: {
    gtmDataLayer() {
      return gtmInstance;
    },
    getGtmEventData() {
      return {
        event: 'games',
        gtmCategory: 'Promoevent',
        source: this.getGtmDataSource(),
        promoeventname: this.$store.state.SelectedEventName,
      };
    },
    getGtmDataSource() {
      return this.$store.getters.isOuterLobby ? 'Worldcup' : 'Gamelobby';
    },
  }
};

</script>
