import Vue from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './lang/i18n';
import Cookies from 'js-cookie';
import {mockApiWorker} from "../mock/browser.ts";
import {installGtm} from '@injoi-utils/vue2-gtm';
import gtmInstance from "@/libraries/gtmInstance";
Vue.config.productionTip = false;

let locale =  Cookies.get('lang') ? (Cookies.get('lang').toLowerCase()).replace("-", "_") : 'en'; 

if (locale === "zh_tw") {
  locale = "zh_cn";
}

// uncomment if you need local development

(async () => {
    if (process.env.VUE_APP_ENV === 'development') {
        const {mockApiWorker} = await import('../mock/browser.ts')
        mockApiWorker.start();
    }


})();
 

i18n.locale = locale;
installGtm(gtmInstance);

new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount('#event-content-div');
